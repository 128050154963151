import {
    ADD_TOAST, ADD_TOAST_ERROR, REMOVE_ERROR_TOAST, REMOVE_TOAST,
} from '../../constants/actionTypes';

const initialState = {
    toasts: [],
    errorToasts: [],
};

const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TOAST: {
            return { ...state, toasts: [...state.toasts, action.payload.message] };
        }
        case ADD_TOAST_ERROR:
            return { ...state, errorToasts: [...state.errorToasts, action.payload.message] };
        case REMOVE_TOAST: {
            const oldState = { ...state };
            const newToasts = oldState.toasts.filter((item) => item !== action.payload.message);

            return { ...state, toasts: newToasts };
        }
        case REMOVE_ERROR_TOAST: {
            const oldState = { ...state };
            const newErrorToasts = oldState.errorToasts.filter((item) => item !== action.payload.message);

            return { ...state, errorToasts: newErrorToasts };
        }
        default:
            return state;
    }
};

export default toastReducer;
