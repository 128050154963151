import React, { useCallback, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GiHamburgerMenu } from 'react-icons/gi';
import AuthButton from '../Auth/AuthButton';
import ContextMenu from './ContextMenu';
import './Navbar.css';
import logo from '../../assets/icon.png';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { id } = useSelector((state) => state.auth);

    const handleToggleMenu = useCallback(() => {
        setIsMenuOpen((prev) => !prev);
    }, []);

    return (
        <nav className="navbar">
            <div className="container">
                <div className="navbar-content">
                    <div className="navbar-header">
                        <div className="logo-section">
                            <Link
                              className="logo-link"
                              to="/"
                              onClick={() => setIsMenuOpen(false)}
                            >
                                <img
                                  className="navbar-logo"
                                  src={logo}
                                  alt="test"
                                />
                                <h1 className="logo">
                                    app
                                    <span className="color-primary">EAT</span>
                                    IT
                                </h1>
                            </Link>
                            <GiHamburgerMenu className="icon toggle-menu-icon" onClick={handleToggleMenu} />
                        </div>
                    </div>
                    <div className={`navbar-body ${isMenuOpen ? 'show' : 'hidden'}`}>
                        <div
                          className="navbar-container"
                          onClick={handleToggleMenu}
                          aria-hidden="true"
                        >
                            <div className="navbar-items">
                                <NavLink
                                  className="navbar-item typo-normal"
                                  to="/"
                                >
                                    Strona główna
                                </NavLink>
                                <NavLink
                                  className="navbar-item typo-normal"
                                  to="/lists"
                                >
                                    Twoje listy
                                </NavLink>
                                <NavLink
                                  className="navbar-item typo-normal"
                                  to="/favourites"
                                >
                                    Ulubione
                                </NavLink>
                                <If condition={id}>
                                    <NavLink
                                      className="navbar-item typo-normal"
                                      to="/priceList"
                                    >
                                        Cennik
                                    </NavLink>
                                </If>
                            </div>
                            <ContextMenu />
                            <div className="auth-btn">
                                <AuthButton className="btn-primary btn-max-width" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
