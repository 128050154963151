import React from 'react';
import './ListSection.css';

function ListSection({ title, children }) {
    return (
        <div className="list-section">
            <h2 className="list-title">{title}</h2>
            <div className="list-section-content">
                {children}
            </div>
        </div>
    );
}

export default ListSection;
