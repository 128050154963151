import React, { useMemo } from 'react';
import './progressBar.css';

function ProgressBar({ percent, total, completedValue }) {
    const completedWidth = useMemo(() => {
        if (percent) {
            return percent;
        }

        const completedValuePercent = completedValue * 100 / total;

        return completedValuePercent;
    }, [completedValue, percent, total]);

    return (
        <div className="progress-bar-container">
            <Choose>
                <When condition={percent}>
                    <p className="typo-normal">
                        {percent}
                        %
                    </p>
                </When>
                <Otherwise>
                    <p className="typo-normal">{completedValue}</p>
                </Otherwise>
            </Choose>
            <div className="progress-bar">
                <div className="progress-bar-completed" style={{ width: `${completedWidth}%` }} />
            </div>
            <Choose>
                <When condition={percent}>
                    <p className="typo-normal">100%</p>
                </When>
                <Otherwise>
                    <p className="typo-normal">{total}</p>
                </Otherwise>
            </Choose>
        </div>

    );
}

export default ProgressBar;
