import React from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer-container">
            <div className="container">
                <div className="footer-content-container">
                    <div className="footer-items typo-normal">
                        <Link to="/privatePolicy" className="footer-item">Regulamin i Polityka prywatności</Link>
                        <div className="footer-item not-clickable">
                            Kontakt: app.eatit.contact@gmail.com
                        </div>
                    </div>
                    <div className="footer-social-media">
                        <a
                          href="https://www.facebook.com/people/AppEatit/100091528357843/"
                          target="_blank"
                          className="footer-item"
                          rel="noreferrer"
                        >
                            <FaFacebookSquare className="footer-icon" />
                        </a>
                        <a
                          href="https://www.instagram.com/app.eatit/"
                          target="_blank"
                          className="footer-item"
                          rel="noreferrer"
                        >
                            <FiInstagram className="footer-icon" />
                        </a>
                    </div>
                    <div className="footer-item typo-small company-name">
                        Powered by Quatil Software
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
