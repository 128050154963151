import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useCookies } from 'react-cookie';
import Navbar from './components/Navbar/Navbar';
import Pages from './pages/Pages';
import store from './redux/store';
import LoadData from './components/LoadData';
import Toasts from './components/Toasts/Toasts';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import CookiesForm from './components/Cookies/CookiesForm';
import { ARE_REQUIRED_COOKIES_ACCEPTED } from './constants/cookies';
import GetUserData from './utils/GetUserData';

function App() {
    const [cookies] = useCookies([ARE_REQUIRED_COOKIES_ACCEPTED]);

    return (
        <Provider store={store}>
            <Router>
                <GetUserData />
                <ScrollToTop />
                <If condition={!cookies.ARE_REQUIRED_COOKIES_ACCEPTED}>
                    <CookiesForm />
                </If>
                <div className="app">
                    <div className="header">
                        <Navbar />
                    </div>
                    <div className="body">
                        <Toasts />
                        <Pages />
                    </div>
                    <div className="footer">
                        <Footer />
                    </div>
                    <LoadData />
                </div>
            </Router>
        </Provider>
    );
}

export default App;
