import React, { useState } from 'react';
import './VerifyEmailPage.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import InputWithLabel from '../components/input/InputWithLabel';
import { forgotPassword } from '../api/fetchApi';
import { addToastMessage } from '../redux/actions/toastAction';
import ButtonInline from '../components/Buttons/ButtonInline';
import FormCard from '../components/FormCard/FormCard';

function ForgotPasswordPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [error, setError] = useState({ input: 'email', message: '' });
    const [isFetching, setIsFetching] = useState(false);

    const handleSendResetPasswordLink = async (e) => {
        e.preventDefault();

        setIsFetching(true);
        try {
            setError({ input: 'email', message: '' });
            await forgotPassword({ email });
            navigate('/');
            dispatch(addToastMessage('Link został wysłany na twój adres email'));
        } catch (err) {
            setError((prev) => ({ ...prev, message: 'Zweryfikuj swój adres email' }));
        }
        setIsFetching(false);
    };

    return (
        <FormCard title="Nie pamiętasz hasła?" onSubmit={handleSendResetPasswordLink}>
            <InputWithLabel
              className="label-auth"
              id="label-login"
              name="email"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
              error={error}
              dataTest="email-input"
            />
            <ButtonInline
              type="submit"
              text="Wyślij link do resetu hasła"
              className="btn-primary btn-large btn-max-width"
              isLoading={isFetching}
              disabled={!email.includes('@')}
              dataTest="submit-reset-password-button"
            />
            <div className="auth-form-bottom-section typo-normal">
                <NavLink
                  className="registration-link"
                  to="/signin"
                >
                    Wróć do logowania
                </NavLink>
            </div>
        </FormCard>
    );
}

export default ForgotPasswordPage;
