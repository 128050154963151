import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import IconButton from '../components/Buttons/IconButton';
import CreateList from '../components/CreateShoppingList/CreateList';
import InfoBar from '../components/snackbar/InfoBar';
import UserShoppingLists from '../components/UserShoppingList/UserShoppingLists';
import Modal from '../components/modal/Modal';
import './ListPage.css';

function UserListPage() {
    const { id } = useSelector((state) => state.auth);

    const [openCreator, setOpenCreator] = useState(false);

    const toggleOpenCreator = useCallback(() => {
        setOpenCreator((prev) => !prev);
    }, []);

    const handleCloseCreator = useCallback(() => {
        setOpenCreator(false);
    }, []);

    return (
        <Choose>
            <When condition={id}>
                <div className="container list-page-container list-page-container-vertical-padding">
                    <div className="lists-container">
                        <UserShoppingLists />
                    </div>
                    <If condition={!openCreator}>
                        <IconButton
                          className="icon-collapse-list-creator"
                          icon={(<AiOutlinePlusCircle size={36} />)}
                          onClick={toggleOpenCreator}
                          dataTest="open-shopping-list-icon"
                        />
                    </If>
                    <div className="big-section create-list">
                        <CreateList
                          containerTitle="Stwórz listę"
                          buttonText="Stwórz listę"
                          onSave={handleCloseCreator}
                        />
                    </div>
                    <If condition={openCreator}>
                        <Modal
                          setOpen={setOpenCreator}
                          title="Stwórz listę"
                        >
                            <CreateList
                              containerTitle="Stwórz listę"
                              buttonText="Stwórz listę"
                              onSave={handleCloseCreator}
                            />
                        </Modal>
                    </If>
                </div>
            </When>
            <Otherwise>
                <div className="container home-page-container">
                    <InfoBar className="big-snackbar">
                        <p className="typo-normal">
                            <NavLink
                              className="registration-link"
                              to="/signin"
                            >
                                Zaloguj się
                            </NavLink>
                            {' '}
                            i twórz swoje listy zakupowe
                        </p>
                    </InfoBar>
                </div>
            </Otherwise>
        </Choose>
    );
}

export default UserListPage;
