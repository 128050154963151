import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import './Snackbar.css';

function ErrorBar({ text }) {
    return (
        <div className="error-bar snackbar">
            <AiOutlineWarning size={24} className="snackbar-icon" />
            <div className="typo-small error-bar-text">
                {text}
            </div>
        </div>
    );
}

export default ErrorBar;
