import { CLEAR_PAGINATION_DATA, GET_PAGINATION_DATA } from '../../constants/actionTypes';

const initialState = {
    currentPage: Number,
    numberOfPages: Number,
};

const paginationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAGINATION_DATA: {
            const { currentPage, numberOfPages } = action.data;

            return { currentPage, numberOfPages };
        }
        case CLEAR_PAGINATION_DATA: {
            return {};
        }
        default:
            return state;
    }
};

export default paginationReducer;
