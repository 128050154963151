import React, { useMemo } from 'react';
import LoaderOnAllSite from '../Loader/LoaderOnAllSite';
import './RecipeSteps.css';

function RecipeSteps({ steps }) {
    const stepsElement = useMemo(() => (
        <Choose>
            <When condition={steps}>
                {steps.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="step-item typo-normal" key={index}>
                        <div className="circular-index">{index + 1}</div>
                        <div className="steps-text">{item}</div>
                    </div>
                    ))}
            </When>
            <Otherwise>
                <LoaderOnAllSite />
            </Otherwise>
        </Choose>
    ), [steps]);

    return (
        <div className="recipe-steps-container">
            {stepsElement}
        </div>
    );
}

export default RecipeSteps;
