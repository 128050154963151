import React, { useMemo } from 'react';
import RecipesGridItem from './RecipesGridItem';
import './Recipes.css';

function RecipesGrid({ recipes }) {
    const recipesGridItems = useMemo(() => (
        recipes.map((item) => <RecipesGridItem key={item.id} recipe={item} />)
    ), [recipes]);

    return (
        <div className="grid-container">
            {recipesGridItems}
        </div>
    );
}

export default RecipesGrid;
