import React, {
    useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getRecipeById } from '../redux/actions/recipesAction';
import ModalCreateOrAddToList from '../components/modal/ModalCreateOrAddToList';
import { getUserShoppingList } from '../redux/actions/shoppingListAction';
import AuthButton from '../components/Auth/AuthButton';
import BackHistoryArrow from '../components/BackHistoryArrow/BackHistoryArrow';
import FavouriteButton from '../components/FavouriteButton/FavouriteButton';
import ButtonInline from '../components/Buttons/ButtonInline';
import LoaderOnAllSite from '../components/Loader/LoaderOnAllSite';
import { RECIPE_LEVELS } from '../constants/levels';
import './RecipePage.css';
import RecipeMetadata from '../components/RecipePage/RecipeMetadata';
import IngredientsList from '../components/RecipePage/IngredientsList';
import RecipeSteps from '../components/RecipePage/RecipeSteps';

function RecipePage() {
    const dispatch = useDispatch();

    const { id: userId } = useSelector((state) => state.auth);
    const recipes = useSelector((state) => state.recipes);
    const { id: recipeId } = useParams();

    const recipe = useMemo(() => (
        recipes.find((item) => item.id === recipeId)
    ), [recipeId, recipes]);
    const [modalVisible, setModalVisible] = useState(false);

    const {
        author,
        categoriesIds,
        defaultNumberOfServings,
        duration,
        image,
        ingredients,
        kcal,
        levelId,
        link,
        steps,
        title,
    } = recipe ?? {};

    const levelName = useMemo(() => (
        RECIPE_LEVELS.find((level) => level.id === levelId)?.name
    ), [levelId]);

    useEffect(() => {
        if (userId) {
            dispatch(getUserShoppingList(userId));
        }

        dispatch(getRecipeById(recipeId));
    }, [dispatch, recipeId, userId]);

    return (
        <div className="container">
            <If condition={modalVisible}>
                <ModalCreateOrAddToList open={modalVisible} setOpen={setModalVisible} />
            </If>
            <Choose>
                <When condition={recipe}>
                    <div className="content">
                        <BackHistoryArrow />
                        <div className="recipe-page-content">
                            <div className="recipe-page-left-section">
                                <div className="center-metadata-container">
                                    <h2 className="recipe-title">
                                        {title}
                                    </h2>
                                    <RecipeMetadata
                                      servings={defaultNumberOfServings}
                                      duration={duration}
                                      level={levelName}
                                      kcal={kcal}
                                    />
                                    <IngredientsList ingredients={ingredients} />
                                    <div className="recipe-actions">
                                        <Choose>
                                            <When condition={userId}>
                                                <ButtonInline
                                                  className="btn-primary btn-large"
                                                  text="Dodaj składniki do listy"
                                                  onClick={() => setModalVisible((prev) => !prev)}
                                                  dataTest="add-ingredient-button"
                                                />
                                            </When>
                                            <Otherwise>
                                                <AuthButton className="btn-primary btn-large" />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                            </div>
                            <div className="recipe-page-image-section">
                                <img src={image} alt={`obrazek przedstawia ${title}`} />
                                <If condition={userId}>
                                    <div className="recipe-page-favorite-button">
                                        <FavouriteButton recipeId={recipeId} iconButton={false} />
                                    </div>
                                </If>
                            </div>
                            <div className="recipe-page-right-section">
                                <RecipeSteps steps={steps} />
                            </div>
                        </div>
                    </div>
                </When>
                <Otherwise>
                    <LoaderOnAllSite />
                </Otherwise>
            </Choose>
        </div>
    );
}

export default RecipePage;
