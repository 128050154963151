import React from 'react';
import { FaCookieBite } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import ButtonInline from '../Buttons/ButtonInline';
import './Cookies.css';
import { ARE_REQUIRED_COOKIES_ACCEPTED } from '../../constants/cookies';

function CookiesForm() {
    const [cookies, setCookie] = useCookies([ARE_REQUIRED_COOKIES_ACCEPTED]);

    return (
        <div className="cookies-layout">
            <div className="container">
                <div className="cookies-container">
                    <div className="cookies-header">
                        <FaCookieBite size={40} style={{ marginRight: 10, color: 'var(--color-primary)' }} />
                        <h2>Cenimy Twoją prywatność</h2>
                    </div>
                    <div className="cookies-body typo-small">
                        <strong>Szanowni Państwo,</strong>
                        <br />
                        Niniejsza strona internetowa wykorzystuje pliki cookies w celu zapewnienia Państwu jak najlepszych wrażeń z korzystania z naszej witryny. Pliki cookies to małe pliki tekstowe, które są przechowywane na Państwa urządzeniu końcowym (takim jak komputer, tablet czy smartfon), gdy odwiedzają Państwo naszą witrynę. Pliki cookies pomagają nam poprawiać jakość naszych usług oraz dostosować je do Państwa potrzeb i preferencji.
                        <br />
                        <br />
                        Korzystając z naszej strony internetowej, wyrażają Państwo zgodę na wykorzystanie przez nas plików cookies zgodnie z naszą polityką prywatności. Mogą Państwo w każdym czasie zmienić ustawienia dotyczące plików cookies w swojej przeglądarce internetowej.
                        <br />
                        <br />
                        Dziękujemy za korzystanie z naszej strony internetowej.
                    </div>
                    <div className="cookies-actions">
                        <ButtonInline
                          text="Akceptuje cookies"
                          className="btn-primary btn-large"
                          onClick={() => setCookie(ARE_REQUIRED_COOKIES_ACCEPTED, true)}
                          dataTest="accept-cookies-button"
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CookiesForm;
