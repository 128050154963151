import React from 'react';
import { useSelector } from 'react-redux';
import Toast from './Toast';
import './Toasts.css';

function Toasts() {
    const { toasts, errorToasts } = useSelector((state) => state.toasts);

    const toastsItems = toasts.map((toast) => (
        <Toast text={toast} key={toast} />
    ));

    const errorToastsItems = errorToasts.map((toast) => (
        <Toast text={toast} key={toast} error />
    ));

    return (
        <div className="toasts" aria-live="polite" aria-atomic="true">
            <div className="toasts-container">
                {toastsItems}
                {errorToastsItems}
            </div>
        </div>
    );
}

export default Toasts;
