import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import './Buttons.css';

function ButtonInline({
    className, text, onClick, disabled, isLoading, type, dataTest,
}) {
    return (
        <button
          // eslint-disable-next-line react/button-has-type
          type={type ?? 'button'}
          className={className}
          onClick={onClick}
          disabled={disabled || isLoading}
          data-test={dataTest}
        >
            <Choose>
                <When condition={isLoading}>
                    <ThreeDots
                      height="20"
                      width="50"
                      radius="9"
                      color="var(--color-primary-lightest)"
                      ariaLabel="three-dots-loading"
                      visible
                    />
                </When>
                <Otherwise>
                    {text}
                </Otherwise>
            </Choose>
        </button>
    );
}

export default ButtonInline;
