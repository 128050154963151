import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FaForward, FaBackward } from 'react-icons/fa';
import './Pagination.css';
import PaginationItem from './PaginationItem';

function Pagination({ id, pagePath }) {
    const navigate = useNavigate();
    const { currentPage, numberOfPages } = useSelector((state) => state.pagination);

    return (
        <If condition={numberOfPages > 1}>
            <div className="pagination-container">
                <If condition={currentPage >= 4}>
                    <PaginationItem onClick={() => navigate(`/${pagePath}/${id}/1`)}>
                        <FaBackward size={12} />
                    </PaginationItem>
                </If>
                <If condition={currentPage > 2}>
                    <PaginationItem onClick={() => navigate(`/${pagePath}/${id}/${currentPage - 2}`)}>
                        {currentPage - 2}
                    </PaginationItem>
                </If>
                <If condition={currentPage > 1}>
                    <PaginationItem onClick={() => navigate(`/${pagePath}/${id}/${currentPage - 1}`)}>
                        {currentPage - 1}
                    </PaginationItem>
                </If>
                <PaginationItem active>
                    {currentPage}
                </PaginationItem>
                <If condition={(numberOfPages - currentPage) > 0}>
                    <PaginationItem onClick={() => navigate(`/${pagePath}/${id}/${currentPage + 1}`)}>
                        {currentPage + 1}
                    </PaginationItem>
                </If>

                <If condition={(numberOfPages - currentPage) > 1}>
                    <PaginationItem onClick={() => navigate(`/${pagePath}/${id}/${currentPage + 2}`)}>
                        {currentPage + 2}
                    </PaginationItem>
                </If>

                <If condition={(numberOfPages >= 4) && (numberOfPages - currentPage > 2)}>
                    <PaginationItem onClick={() => navigate(`/${pagePath}/${id}/${numberOfPages}`)}>
                        <FaForward size={12} />
                    </PaginationItem>
                </If>
            </div>
        </If>
    );
}

export default Pagination;
