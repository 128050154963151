import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdAddShoppingCart } from 'react-icons/md';
import { BiListPlus } from 'react-icons/bi';
import { useParams } from 'react-router';
import { createShoppingList, editUserShoppingList } from '../../redux/actions/shoppingListAction';
import InputWithLabel from '../input/InputWithLabel';
import ButtonInline from '../Buttons/ButtonInline';
import Modal from './Modal';
import './Modal.css';

function ModalCreateOrAddToList({ setOpen }) {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { id: userId } = useSelector((state) => state.auth);
    const { shoppingLists } = useSelector((state) => state.shoppingList);
    const recipes = useSelector((state) => state.recipes);

    const recipe = useMemo(() => (
        recipes.find((item) => item.id === id)
    ), [id, recipes]);

    const [createNewList, setCreateNewList] = useState(false);
    const [listTitle, setListTitle] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const handleToggleCreatingList = useCallback((e) => {
        e.preventDefault();
        setCreateNewList((prev) => !prev);
    }, []);

    const handleMergeRecipeWithList = useCallback((list) => {
        const mergeIngredients = [...list.ingredients];
        const mergeIngredientsNames = mergeIngredients.map((item) => item.name);

        recipe.ingredients.forEach((recipeItem) => {
            if (mergeIngredientsNames.includes(recipeItem.name)) {
                const objIndex = mergeIngredients.findIndex((obj) => obj.name === recipeItem.name);
                mergeIngredients[objIndex].amount += recipeItem.amount;
                mergeIngredients[objIndex].isSelected = false;
            } else {
                mergeIngredients.push(recipeItem);
            }
        });

        dispatch(editUserShoppingList({
            id: list.id,
            ingredients: [...mergeIngredients],
            relatedRecipeIds: list.relatedRecipe ? [...list.relatedRecipe, recipe.id] : [recipe.id],
        }));

        setOpen(false);
    }, [dispatch, recipe.id, recipe.ingredients, setOpen]);

    const handleCreateList = useCallback((e) => {
        e.preventDefault();
        setIsFetching(true);

        dispatch(createShoppingList({
            userId,
            title: listTitle,
            ingredients: recipe.ingredients.map((item) => ({
                id: item.id,
                amount: item.amount,
                isSelected: false,
            })),
            relatedRecipeIds: [recipe.id],
        })).then(() => {
            setOpen(false);
            setIsFetching(false);
        });
    }, [dispatch, listTitle, recipe.id, recipe.ingredients, setOpen, userId]);

    const listsElements = useMemo(() => (
        shoppingLists?.map((item) => (
            <div
              className="modal-body-item"
              key={item.id}
              onClick={() => handleMergeRecipeWithList(item)}
              aria-hidden="true"
            >
                <MdAddShoppingCart size={36} />
                <p className="typo-normal">
                    {item.title}
                </p>
            </div>
        ))
    ), [handleMergeRecipeWithList, shoppingLists]);

    return (
        <Modal setOpen={setOpen} title="Dodaj składniki do listy lub stwórz nową listę">
            <Choose>
                <When condition={!createNewList}>
                    <div className="modal-body-container">
                        <div className="modal-body-grid">
                            <div
                              className="modal-body-item"
                              onClick={handleToggleCreatingList}
                              aria-hidden="true"
                            >
                                <BiListPlus size={36} />
                                <p className="typo-normal"> Swtórz nową listę </p>
                            </div>
                            {listsElements}
                        </div>
                    </div>
                </When>
                <Otherwise>
                    <div className="modal-body-container">
                        <InputWithLabel
                          className="dark max-width"
                          id="listTitle"
                          name="Nazwa listy"
                          type="text"
                          placeholder="Wprowadź nazwe listy"
                          value={listTitle}
                          onChange={(e) => setListTitle(e.target.value)}
                          dataTest="list-title-input"
                        />
                    </div>
                </Otherwise>
            </Choose>
            <div className="modal-footer">
                <Choose>
                    <When condition={createNewList}>
                        <ButtonInline
                          text="Swtórz listę"
                          className="btn-primary btn-large btn-modal-margin"
                          onClick={handleCreateList}
                          isLoading={isFetching}
                          disable={!listTitle.length}
                          dataTest="create-list-button"
                        />
                        <ButtonInline
                          text="Anuluj"
                          className="btn-primary btn-large"
                          onClick={handleToggleCreatingList}
                          dataTest="cancel-button"
                        />
                    </When>
                    <Otherwise>
                        <ButtonInline
                          text="Anuluj"
                          className="btn-primary btn-large"
                          onClick={() => setOpen(false)}
                          dataTest="cancel-button"
                        />
                    </Otherwise>
                </Choose>
            </div>
        </Modal>
    );
}

export default ModalCreateOrAddToList;
