import React from 'react';

function PaginationItem({ onClick, children, active }) {
    return (
        <div
          className={`pagination-item-btn${active ? ' active' : ''}`}
          onClick={onClick}
          aria-hidden="true"
        >
            <div className="pagination-item typo-small">
                {children}
            </div>
        </div>
    );
}

export default PaginationItem;
