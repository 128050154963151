import { GET_ALL_PRODUCTS } from '../../constants/actionTypes';

const initialState = [];

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS: {
            const { products } = action.data;

            return products;
        }
        default:
            return state;
    }
};

export default productReducer;
