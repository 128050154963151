import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getTransactionById } from '../api/fetchApi';
import LoaderOnAllSite from '../components/Loader/LoaderOnAllSite';
import './TransactionStatus.css';

function TransactionStatus() {
    const { id } = useParams();
    const [status, setStatus] = useState(null);
    const [checkTransactionInterval, setCheckTransactionInterval] = useState();
    const [attemptCounter, setAttemptCounter] = useState(0);
    const attemptToCheckTransaction = 5;

    useEffect(() => {
        const getTransactionStatus = async () => {
            const { data } = await getTransactionById(id);

            return data.status;
        };

        getTransactionStatus()
            .then((res) => setStatus(res));

        setCheckTransactionInterval(
            setInterval(() => {
                getTransactionStatus()
                    .then((res) => setStatus(res));
                setAttemptCounter((prev) => prev + 1);
            }, 10000),
        );
    }, [id]);

    useEffect(() => {
        if (status === 'available' || attemptCounter >= attemptToCheckTransaction) {
            clearInterval(checkTransactionInterval);
        }
    }, [attemptCounter, checkTransactionInterval, status]);

    return (
        <div className="container">
            <div className="content transaction-status-page">
                <Choose>
                    <When condition={status === 'available'}>
                        <h1>Dziękujemy za zakup</h1>
                        <p className="typo-normal">Otrzymałeś dostęp do produktu, ciesz się rozszerzonym dostępem!</p>
                    </When>
                    <When condition={status !== 'available' && attemptCounter < attemptToCheckTransaction}>
                        <h1>Przetwarzamy twoją transakcję</h1>
                        <LoaderOnAllSite />
                    </When>
                    <Otherwise>
                        <h1>Niestesty zajęło to więcej czasu niż zakładaliśmy</h1>
                        <p className="typo-normal">
                            Jeżeli poprawnie dokonałeś płatności,
                            nabędziesz dostęp do produktu jak tylko transakcja zostanie przeprocesowana.
                        </p>
                    </Otherwise>
                </Choose>
            </div>
        </div>
    );
}

export default TransactionStatus;
