import React, { useMemo } from 'react';
import Checkbox from '../Buttons/Checkbox';

function AgreementsList({ agreements, onChange }) {
    const agreementsItems = useMemo(() => (
        agreements.map((item) => (
            <div key={item.id}>
                <Checkbox
                  className="agreement-checkbox typo-small"
                  label={item.description}
                  isRequire={item.isRequire}
                  onChange={onChange}
                  item={item}
                  dataTest={`${item.isRequire ? 'require' : 'optional'}-agreement-checkbox`}
                />
            </div>
        ))
    ), [agreements, onChange]);

    return agreementsItems;
}

export default AgreementsList;
