import React from 'react';
import './Snackbar.css';

function InfoBar({ className, children }) {
    return (
        <div className={`info-bar snackbar ${className}`}>
            <div className="typo-small info-bar-text">
                {children}
            </div>
        </div>
    );
}

export default InfoBar;
