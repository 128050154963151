import {
    GET_ALL_AGREEMENTS,
} from '../../constants/actionTypes';

const initialState = [];

const agreementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_AGREEMENTS: {
            const { agreements } = action.data;

            return agreements;
        }
        default:
            return state;
    }
};

export default agreementsReducer;
