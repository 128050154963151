import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ListSection from '../PageSections/ListSection';
import './Persons.css';

function PersonsList() {
    const navigate = useNavigate();
    const { people } = useSelector((state) => state);

    const peopleListItem = useMemo(() => (
        people.map((item) => (
            <div key={item.id}>
                <div
                  className="item-container people-list-item"
                  onClick={() => { navigate(`/person/${item.id}/1`); }}
                  aria-hidden="true"
                >
                    <div
                      type="button"
                      className="poster-list-item-image"
                      style={{ backgroundImage: `url(${item.image})` }}
                    />
                    <p className="list-item-title typo-normal">
                        {item.name}
                    </p>
                </div>
            </div>
        ))
    ), [navigate, people]);

    return (
        <ListSection title="Nasze gwiazdy">
            <Slider
              infinite={peopleListItem.length > 2}
              speed={500}
              slidesToShow={3}
              slidesToScroll={3}
              centerMode
              responsive={[
                {
                  breakpoint: 1336,
                  settings: {
                    centerMode: false,
                  },
                },
                {
                  breakpoint: 1030,
                  settings: {
                    infinite: peopleListItem.length > 1,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: false,
                  },
                },
                {
                  breakpoint: 690,
                  settings: {
                    infinite: peopleListItem.length > 0,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                  },
                },
              ]}
            >
                {peopleListItem}
            </Slider>
        </ListSection>
    );
}

export default PersonsList;
