import * as api from '../../api/fetchApi';
import { GET_USER_DATA } from '../../constants/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const getUserData = (id) => async (dispatch) => {
    const { data } = await api.apiGetUserData(id);

    dispatch({
        type: GET_USER_DATA,
        data,
    });
};
