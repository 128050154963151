import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import RecipesGrid from '../components/Recipes/RecipesGrid';
import InfoBar from '../components/snackbar/InfoBar';
import { getUserFavorites } from '../redux/actions/favouriteAction';
import LoaderOnAllSite from '../components/Loader/LoaderOnAllSite';

function FavouritesPage() {
    const dispatch = useDispatch();
    const { id } = useSelector((state) => state.auth);
    const favourites = useSelector((state) => state.favourites);

    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        if (id) {
            dispatch(getUserFavorites(id))
                .finally(() => setIsFetching(false));
        }
    }, [dispatch, id]);

    return (
        <div className="container home-page-container">
            <Choose>
                <When condition={isFetching}>
                    <LoaderOnAllSite />
                </When>
                <When condition={id && favourites.length}>
                    <div className="list-page-container">
                        <RecipesGrid recipes={favourites} />
                    </div>
                </When>
                <When condition={id && !favourites.length}>
                    <InfoBar className="big-snackbar">
                        <p className="typo-normal">Głodny? Nie masz jeszcze nic w ulubionych.</p>
                        <p className="typo-normal">
                            Przejdź na
                            {' '}
                            <NavLink
                              className="registration-link"
                              to="/"
                            >
                                stronę główną
                            </NavLink>
                            , znajdź przepisy pasujące do Ciebie i dodaj je do ulubionych.

                        </p>
                    </InfoBar>
                </When>
                <Otherwise>
                    <InfoBar className="big-snackbar">
                        <p className="typo-normal">
                            <NavLink
                              className="registration-link"
                              to="/signin"
                            >
                                Zaloguj się
                            </NavLink>
                            {' '}
                            i korzystaj z możliwości dodawania do ulubionych
                        </p>
                    </InfoBar>
                </Otherwise>
            </Choose>
        </div>
    );
}

export default FavouritesPage;
