import React from 'react';

function PrivatePolicyPage() {
    return (
        <div className="container home-page-container">
            <h2 className="list-title">
                Regulamin Serwisu
            </h2>
            <div className="typo-normal">
                <h4>I. Definicje</h4>
                <ul>
                    <li>
                        1. Regulamin
                        <br />
                        Niniejszy regulamin.
                    </li>
                    <li>
                        2. Administrator
                        <br />
                        Jakub Mazurek Quatil Software, wpisany do CEIDG NIP: 8411614268, REGON: 522709881.
                    </li>
                    <li>
                        3. Operator płatności
                        <br />
                        PAYPRO S.A. z siedzibą w Poznaniu, ul. Pastelowa 8, 60-198 Poznań,
                        NIP: 779-236-98-87, REGON: 301345068, Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu,
                        VIII Wydz. Gospodarczy Krajowego Rejestru Sądowego Nr KRS 0000347935,
                        wysokość kapitału zakładowego: 5 476 300,00 zł, wpłacony w całości
                    </li>
                    <li>
                        4. Ambasador
                        <br />
                        Osoba, która została mianowana przez firmę do reprezentowania jej interesów w kontekście marketingu.
                    </li>
                    <li>
                        5. Użytkownik
                        <br />
                        Osoba fizyczna mająca dostęp do serwisu, która wyraziła zgody przewidziane
                        przepisami prawa, które umożliwiają korzystanie z Serwisu.
                    </li>
                    <li>
                        6. Konto
                        <br />
                        Prowadzone dla Użytkownika przez Administratora pod unikalną nazwą (adres mailowy)
                        konto będące zbiorem zasobów, zabezpieczone hasłem, w którym gromadzone są dane
                        Użytkownika oraz informacje o jego działaniach w ramach Serwisu.
                    </li>
                    <li>
                        7. Serwis
                        <br />
                        Strona internetowa lub aplikacja mobilna prowadzona przez administratora pod nazwą “appEatit”
                    </li>
                    <li>
                        8. Rozszerzone funkcjonalności serwisu
                        <br />
                        Funkcjonalności dodatkowe, zablokowane dla użytkowników niezalogowanych lub
                        użytkowników nie posiadających wykupionego pakietu, do których dostęp można nabyć poprzez zakup pakietu.
                    </li>
                    <li>

                        9. Cena Sprzedaży
                        <br />
                        Opłaty uiszczane przez Użytkownika za dostęp do rozszerzonych funkcji serwisu,
                        wyrażone w złotych polskich, zawierające podatek od towarów i usług.
                    </li>
                    <li>
                        10. Okres rozliczeniowy
                        <br />
                        Oznacza miesięczny lub inny (w długości wskazanej przez Administratora) dostęp do Pakietu.
                    </li>
                    <li>
                        11. Zamówienie
                        <br />
                        Zgłoszone przez Użytkownika żądanie odpłatnego udostępnienia rozszerzonych
                        funkcji serwisu zgodnie z postanowieniami Regulaminu.
                    </li>
                </ul>

                <h4>II. Postanowienia ogólne</h4>
                <ul>
                    <li>
                        1. Regulamin określa zasady korzystania z Serwisu, prawa i obowiązki Użytkowników oraz Administratora i Ambasadorów.
                    </li>
                    <li>
                        2. Wszelkie prawa do Serwisu wraz z jego zawartością przysługują Administratorowi.
                    </li>
                    <li>
                        3. Ambasadorzy są odpowiedzialni za wrzucanie treści tj. przepisów wraz ze
                        zdjęciami do serwisu, Administrator nie ponosi odpowiedzialności za treści publikowane przez Ambasadorów.
                    </li>
                    <li>
                        4. Administrator zapewnia informację techniczną w ramach Serwisu.
                    </li>
                </ul>

                <h4>III. Zasady działania Serwisu</h4>
                <ul>
                    <li>
                        1. Rejestracja w Serwisie przez Użytkownika jest dobrowolna i bezpłatna.
                    </li>
                    <li>
                        2. Użytkowanie Serwisu, z wyłączeniem rozszerzonych funkcjonalności, jest nieodpłatne.
                    </li>
                    <li>
                        3. Dostęp w pełnym wymiarze do rozszerzonych funkcjonalności jest odpłatny
                        (z wyjątkiem przypadków, gdy Administrator udostępnił Użytkownikom produkt
                        nieodpłatnie i wyraźnie to skazał) i wymaga spełnienia przez Użytkownika warunków określonych w Regulaminie.
                    </li>
                    <li>
                        4. Korzystanie z Serwisu poprzez ogólnodostępną sieć Internet może wiązać się
                        z zagrożeniem w postaci wirusów komputerowych zaliczanych do złośliwego
                        oprogramowania. Administrator zaleca używanie programów antywirusowych jako profilaktyki antywirusowej.
                    </li>
                    <li>
                        5. Zamówienia w Serwisie można składać poprzez odpowiedni formularz
                        dostępny na stronie Portalu www.appeatit.pl a w przypadkach określonych
                        przez Administratora także za pośrednictwem innych serwisów
                    </li>
                    <li>
                        6. Oferta Serwisu jest dostępna 24 godziny na dobę, przez 7 dni w tygodniu.
                    </li>
                    <li>
                        7. Materiały dostępne w serwisie, do których Użytkownik uzyskuje
                        dostęp za pośrednictwem Serwisu, stanowią przedmiot praw autorskich.
                        Użytkownik zobowiązuje się nie udostępniać publicznie, nie zmieniać,
                        nie kopiować, nie sprzedawać oraz nie rozpowszechniać, w tym także w
                        sieciach komputerowych lub jakichkolwiek systemach teleinformatycznych,
                        udostępnionych mu materiałów tj. przepisów zawartych w serwisie, zarówno w całości jak i w częściach.
                    </li>
                    <li>
                        8. Administrator nie ponosi odpowiedzialności za jakość umieszczanych
                        przez Ambasadorów materiałów tj. przepisów i zdjęć w serwisie.
                    </li>
                    <li>
                        9. Niedopuszczalne jest korzystanie przez Użytkowników z Serwisu w sposób
                        sprzeczny z prawem, postanowieniami Regulaminu, zasadami współżycia społecznego
                        lub dobrymi obyczajami.
                    </li>
                    <li>
                        10. Administrator ma prawo rozwiązać umowę zawartą z Użytkownikiem
                        i tym samym usunąć jego Konto w trybie natychmiastowym, z ważnych
                        przyczyn tj., gdy:
                        <ul>
                            <li>
                                a. Użytkownik narusza którekolwiek z postanowień Regulaminu,
                            </li>
                            <li>
                                b. Użytkownik podjął działania, zmierzające do ingerencji w strukturę Serwisu, utrudnia
                                lub uniemożliwia korzystanie z Serwisu przez innych Użytkowników
                                lub dopuścił się czynu zabronionego przez powszechnie obowiązujące
                                przepisy prawa w związku z korzystaniem z Serwisu.
                            </li>
                        </ul>
                        O wypowiedzeniu Użytkownik zostanie powiadomiony za pośrednictwem poczty
                        elektronicznej. Użytkownikowi przysługuje złożenie reklamacji w związku z
                        rozwiązaniem umowy złożonym przez Administratora, która zostanie rozpatrzona zgodnie z postanowieniami Regulaminu.
                    </li>
                    <li>
                        11. Użytkownik może w każdym czasie rozwiązać umowę z Administratorem
                        (dotyczącą określonego Konta). W tym celu musi przesłać wiadomość e-mail z
                        prośbą o usunięcie konta na adres: app.eatit.contact@gmail.com. Umowa ulega
                        rozwiązaniu niezwłocznie po potwierdzeniu woli usunięcia konta. Dane osobowe
                        Użytkownika zostaną usunięte z bazy danych Serwisu. Administrator nie zwraca
                        jednak w takim wypadku Użytkownikowi Ceny Sprzedaży pobranej już za aktualny
                        okres rozliczeniowy.
                    </li>
                    <li>
                        12. Administrator ma prawo usunąć z bazy danych serwisu konta, których adres
                        email nie został potwierdzony w terminie do 14 dni od dnia rejestracji konta.
                    </li>
                    <li>
                        13. Wylogowanie się z konta w Serwisie czy usunięcie Aplikacji nie powoduje
                        usunięcia/zamknięcia konta Użytkownika ani anulowania Pakietu.
                    </li>
                </ul>

                <h4>
                    IV. Zamówienie i zakup dostępu do rozszerzonych funkcjonalności
                </h4>
                <ul>
                    <li>
                        1. Użytkownik ma prawo do zakupu Dostępu do rozszerzonych funkcjonalności
                        po zalogowaniu się do serwisu poprzez podanie ustalonych przez siebie i
                        zatwierdzonych w trakcie procesu rejestracji: hasła oraz adresu mailowego,
                        zgodnie z instrukcją dostępną Użytkownikowi w trakcie procesu rejestracji.
                        Do rejestracji wymagana jest akceptacja przez Użytkownika Regulaminu wraz z Polityką Prywatności.
                    </li>
                    <li>
                        2. Uiszczenie Ceny Sprzedaży na zasadach określonych w Regulaminie oraz zgodnie z aktualną ofertą handlową
                        Administratora podaną każdorazowo do wiadomości Użytkowników za pośrednictwem
                        Serwisu upoważnia do korzystania z Materiałów rozszerzonych funkcjonalności objętych Pakietem.
                    </li>
                    <li>
                        3. Zakup dostępu do rozszerzonych funkcjonalności ważny jest przez okres podany
                        w ofercie widocznej w Serwisie od chwili jego zakupu np. przez 30 dni.
                        Po upływie tego okresu użytkownik traci dostęp do rozszerzonych funkcjonalności.

                    </li>
                    <li>
                        4. W momencie zawarcia umowy w sekcji &quot;Cennik&quot; dostępnej dla danego Użytkownika
                        po zalogowaniu w serwisie, widoczna jest informacja o aktywnym produkcie.
                    </li>
                    <li>
                        5. Wszelkie działania podejmowane przez osoby trzecie, które mają dostęp do
                        Komputera Użytkownika lub jego Aplikacji podczas aktywnego zalogowania się
                        do Serwisu, są odnotowywane jako działania Użytkownika. Użytkownik ponosi
                        wyłączną odpowiedzialność za konsekwencje udostępnienia Komputera i Aplikacji
                        lub pozostawienia ich bez nadzoru Użytkownika.
                    </li>
                    <li>
                        6. Użytkownik składając Zamówienie oświadcza, że zapoznał się, rozumie
                        i akceptuje postanowienia niniejszego Regulaminu.
                    </li>
                    <li>
                        7. Użytkownik jest każdorazowo powiadamiany o wysokości Ceny Sprzedaży.
                        Administratorowi przysługuje prawo do zmiany Ceny dostępu do rozszerzonych
                        funkcjonalności zgodnie ze swoją aktualną ofertą handlową podaną do wiadomości
                        Użytkowników za pośrednictwem Serwisu, co nie dotyczy Pakietów już Zamówionych przez Użytkowników.
                    </li>
                    <li>
                        8. Po uiszczeniu przez Użytkownika Ceny Sprzedaży oraz rozpoczęciu korzystania
                        przez użytkownika z funkcjonalności serwisu takich jak: tworzenie, edycja lub
                        usuwanie list zakupowych, nie jest możliwe odstąpienie od umowy, chyba że pojawią
                        się problemy z dostępem do rozszerzonych funkcjonalności.
                        Wszelkie reklamacje dotyczące rozszerzonych funkcjonalności będą rozpatrywane zgodnie
                        z niniejszym Regulaminem i przepisami prawa powszechnie obowiązującego.
                    </li>
                </ul>

                <h4>V. Opłaty</h4>
                <ul>
                    <li>
                        1. Ceny Sprzedaży dostępu do dodatkowych funkcjonalności wskazane są każdorazowo
                        i udostępniane Użytkownikom przy zakupie.
                    </li>
                    <li>
                        2. Uiszczenie opłaty za dostęp do dodatkowych funkcjonalności za pośrednictwem
                        Serwisu upoważnia do korzystania z dodatkowych funkcjonalności przez okres podany
                        w danej ofercie liczony od momentu realizacji płatności.
                    </li>
                    <li>
                        3. Zakup produktu dotyczy tylko jednego Użytkownika i/lub jednego gospodarstwa
                        domowego. Wszelkie naruszenia tego zapisu po stronie Użytkownika skutkować będą
                        natychmiastowym rozwiązaniem umowy przez Administratora.
                    </li>
                    <li>
                        4. Uiszczenie opłaty za dostęp do korzystania z dodatkowych funkcjonalności
                        Serwisu zgodnie z podaną do wiadomości Użytkowników aktualną ofertą handlową.
                        Czas dostępności jest określony każdorazowo przy Pakiecie. Ceny wskazane są przy każdym produkcie na liście cennika.
                    </li>
                    <li>
                        5. Powyższe opłaty wyrażone są w złotych polskich, zawierają w sobie podatek od
                        towarów i usług w wysokości przewidzianej prawem oraz uiszczane są wyłącznie za
                        pośrednictwem Operatora Płatności na zasadach określonych w punkcie VI. Regulaminu.
                    </li>
                </ul>

                <h4>VI. Płatności</h4>
                <ul>
                    <li>
                        1. Płatności Ceny Sprzedaży można dokonywać jednorazowo za dostęp do pojedynczego Pakietu.
                    </li>
                    <li>
                        2. Płatności można dokonać za pomocą  metod udostępnianych przez Operatora Płatności.
                    </li>
                    <li>
                        3. Administrator nie świadczy na rzecz Użytkowników żadnych usług płatniczych
                        ani nie odbiera od nich bezpośrednio jakichkolwiek płatności.
                    </li>
                    <li>
                        4. Czas uzyskania Dostępu do pakietu przez Użytkownika może się różnić w zależności
                        od wybranego przez Użytkownika instrumentu płatniczego i wynosić od kilku sekund do kilkudziesięciu godzin.
                    </li>
                </ul>

                <h4>VII. Odstąpienie od umowy</h4>
                <ul>
                    <li>
                        1. Użytkownik ma prawo do odstąpienia od umowy w ciągu 14
                        dni od dnia jej zawarcia bez podania jakiejkolwiek przyczyny.
                    </li>
                    <li>
                        2. Zawarcie umowy o świadczenie usługi udostępnienia Pakietu a następuje w momencie
                        uiszczenia płatności za Zamówienie.
                    </li>
                    <li>
                        3. Użytkownik traci prawo do odstąpienia od umowy w momencie rozpoczęcia świadczenia usługi Dostępu do Pakietu
                    </li>
                    <li>
                        4. Rozpoczęciem świadczenia usługi jest wykonanie dowolnej akcji na listach zakupowych, między innymi:
                        tworzenie, edycja, usuwanie lub zaznaczanie/odznaczanie wybranych produktów.
                    </li>
                    <li>
                        5. Odstąpienie od umowy następuje w drodze jednoznacznego oświadczenia o
                        odstąpieniu od umowy przesłanego na adres poczty elektronicznej: app.eatit.contact@gmail.com
                    </li>
                    <li>
                        6. Po przesłaniu oświadczenia o odstąpieniu od umowy drogą elektroniczną
                        Użytkownik otrzyma niezwłocznie potwierdzenie otrzymania przez Administratora informacji o odstąpieniu od umowy.
                    </li>
                    <li>
                        7. W przypadku płatności online (karty kredytowe, przelewy) zwrot zostanie
                        wykonany przez Administratora za pośrednictwem Operatora Płatności.
                    </li>
                </ul>

                <h4>VIII. Reklamacje dotyczące Serwisu</h4>
                <ul>
                    <li>
                        1. W przypadku reklamacji dotyczącej usług świadczonych za pośrednictwem Serwisu
                        należy kontaktować się z Administratorem pisemnie, za pomocą emaila: app.eatit.contact@gmail.com
                    </li>
                    <li>
                        2. Zgłaszając reklamację należy podać: dane Konta (adres email), którego zgłoszenie dotyczy, dane kontaktowe
                        (adres korespondencyjny lub adres email), dowód zakupu oraz dokładnie opisać przedmiot reklamacji.
                    </li>
                    <li>
                        3. Reklamacje będą rozpatrywane w terminie 14 dni od daty wpływu na adres
                        Administratora zgodnie z powszechnie obowiązującymi przepisami prawa.
                    </li>
                </ul>

                <h4>IX. Reklamacje dotyczące instrumentów płatniczych</h4>
                <p>
                    W przypadku płatności online (karty kredytowe, przelewy) reklamacje należy składać na
                    adres mailowy Administratora: app.eatit.contact@gmail.com. Reklamacja
                    zostanie niezwłocznie przekazana Operatorowi Płatności. Zgłoszenie powinno
                    zawierać numer, kwotę oraz datę transakcji. Operator Płatności obowiązany
                    jest przesłać do Administratora odpowiedź w terminie niezwłocznym. W uzasadnionych
                    przypadkach, spowodowanych wymogiem uzyskania innych potwierdzeń lub dokumentacji
                    transakcji, Operator Płatności przekaże informację Administratorowi z podaniem
                    terminu planowanej odpowiedzi.
                </p>

                <h4>X. Postanowienia końcowe</h4>
                <ul>
                    <li>
                        1. Regulamin jest udostępniony w Serwisie w sposób umożliwiający pozyskiwanie, odtwarzanie i utrwalanie jego treści.
                    </li>
                    <li>
                        2. Administrator może zmienić Regulamin ze względu na:
                        <ul>
                            <li>
                                a. konieczność dostosowania postanowień do obowiązujących przepisów prawa
                                lub decyzji uprawnionych władz publicznych,
                            </li>
                            <li>
                                b. na skutek zmiany interpretacji przepisów powszechnie obowiązującego prawa
                                mającej wpływ na treść Regulaminu,
                            </li>
                            <li>
                                c. wskutek orzeczeń sądów, decyzji, rekomendacji lub zaleceń właściwych w
                                danym zakresie urzędów lub organów,
                            </li>
                            <li>
                                d. konieczność usunięcia błędów lub omyłek pisarskich znajdujących się w
                                treści Regulaminu, zmianę danych, w tym np. danych teleadresowych, nazw,
                                danych kontaktowych czy aktualizację linków zamieszczonych w treści Regulaminu.
                            </li>
                        </ul>
                        Zmiana staje się skuteczna dla Użytkownika w chwili jej wprowadzenia.
                        Użytkownik zostaje powiadomiony o zmianach w regulaminie i w każdej
                        chwili ma prawo Odmowy akceptacji regulaminu. Pakiety zamówione przed
                        wejściem w życie zmian są prowadzone na zasadach dotychczasowych.
                        Odmowa akceptacji zmian jest równoznaczna ze wstrzymaniem przez Administratora
                        możliwości korzystania z Pakietów udostępnianych w Serwisie do czasu zaakceptowania
                        regulaminu w zmienionej wersji, z zastrzeżeniem zapisu ze zdania poprzedniego.
                    </li>
                    <li>
                        3. W sprawach nieuregulowanych Regulaminem zastosowanie znajdują obowiązujące
                        przepisy prawa, w szczególności przepisy ustawy z dnia 23 kwietnia 1964 r. -
                        Kodeks cywilny, ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach
                        pokrewnych, ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,
                        ustawy z dnia 30 maja 2014 r. o prawach konsumenta.
                    </li>
                </ul>
            </div>

            <h2 className="list-title">Polityka prywatności</h2>
            <div className="typo-normal">
                <p>
                    <ul>
                        <li>
                            1. Dla Właściciela  niniejszej strony internetowej, ochrona danych osobowych
                            Użytkowników jest sprawą najwyższej wagi. Dokłada on ogrom starań,
                            aby Użytkownicy czuli się bezpiecznie, powierzając swoje dane osobowe
                            w trakcie korzystania ze strony internetowej.
                        </li>
                        <li>
                            2. Użytkownik to osoba fizyczna, osoba prawna albo jednostka organizacyjna,
                            nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną,
                            korzystająca z usług elektronicznych, dostępnych w ramach strony internetowej.
                        </li>
                        <li>
                            3. Niniejsza polityka prywatności wyjaśnia zasady i zakres przetwarzania
                            danych osobowych Użytkownika, przysługujące mu prawa, jak też obowiązki
                            administratora tych danych, a także informuje o używaniu plików cookies.
                        </li>
                        <li>
                            4. Administrator stosuje najnowocześniejsze środki techniczne i
                            rozwiązania organizacyjne, zapewniające wysoki poziom ochrony przetwarzanych
                            danych osobowych oraz zabezpieczenia przed dostępem osób nieupoważnionych.
                        </li>
                    </ul>
                </p>

                <h4>I. ADMINISTRATOR DANYCH OSOBOWYCH</h4>
                <p>
                    Administratorem danych osobowych jest Przedsiębiorca Pan Jakub Mazurek,
                    prowadzący działalność gospodarczą pod firmą: Jakub Mazurek Quatil Software,
                    NIP: 8411614268 (zwany dalej: „Właściciel&quot;).
                </p>

                <h4>II. CEL PRZETWARZANIA DANYCH OSOBOWYCH</h4>
                <p>
                    <ul>
                        <li>
                            1. Administrator przetwarza dane osobowe:
                            adres e-mail, polubione treści, listy zakupowe użytkowników,
                            transakcje użytkownika, decyzje na zgody użytkowników.
                        </li>
                        <li>
                            2. Oznacza to, że dane te są potrzebne w szczególności do
                            <ul>
                                <li>
                                    a. zarejestrowania się na stronie internetowej;
                                </li>
                                <li>
                                    b. zawarcia umowy;
                                </li>
                                <li>
                                    c. dokonania płatności poprzez przelewy24.
                                </li>
                                <li>
                                    d. dokonania rozliczeń;
                                </li>
                                <li>
                                    e. dostarczenia zakupionych przez Użytkownika dostępu do dodatkowych funkcjonalności;
                                </li>
                            </ul>
                        </li>
                        <li>
                            3. Użytkownik może również wyrazić zgodę na otrzymywanie informacji
                            o nowościach i promocjach, co spowoduje, że administrator będzie
                            również przetwarzać dane osobowe, w celu przesyłania Użytkownikowi
                            informacji handlowych, dotyczących m.in. nowych produktów lub
                            usług, promocji czy wyprzedaży.
                        </li>
                        <li>
                            4. Dane osobowe są również przetwarzane w ramach wypełnienia
                            prawnych obowiązków, ciążących na administratorze danych oraz
                            realizacji zadań, w interesie publicznym m.in. do wykonywania zadań,
                            związanych z bezpieczeństwem i obronnością lub przechowywaniem
                            dokumentacji podatkowej.
                        </li>
                        <li>
                            5. Dane osobowe mogą być również przetwarzane w celach marketingu
                            bezpośredniego produktów, zabezpieczenia i dochodzenia roszczeń lub
                            ochrony przed roszczeniami Użytkownika lub osoby trzeciej,
                            jak również marketingu usług i produktów podmiotów trzecich lub
                            marketingu własnego, niebędącego marketingiem bezpośrednim.
                        </li>
                    </ul>
                </p>

                <h4>III. RODZAJ DANYCH</h4>
                1. Administrator przetwarza następujące dane osobowe, których podanie jest niezbędne do:
                <ul>
                    <li>
                        a. zarejestrowania się na stronie internetowej:
                        - adres e-mail;
                    </li>
                    <li>
                        b. dokonywania zakupów za pomocą strony internetowej jest wykonywane za pośrednictwem przelewy24,
                        aby dokonać zakupy należy wyrazić zogdę na ich regulamin i politykę prywatności.
                    </li>
                </ul>

                <h4>IV. PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH</h4>
                <ul>
                    <li>
                        1. Dane osobowe są przetwarzane zgodnie z przepisami Rozporządzenia Parlamentu
                        Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.
                        w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych
                        i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
                        95/46/WE (ogólne rozporządzenie o ochronie danych), OJ L 119, 4.5.2016, p.
                        1-88, dalej zwane: „rozporządzenie RODO&quot;.
                    </li>
                    <li>
                        2. Administrator przetwarza dane osobowe wyłącznie po uprzednim uzyskaniu zgody Użytkownika,
                        wyrażonej w chwili rejestracji na stronie internetowej.
                    </li>
                    <li>
                        3. Wyrażenie zgody na przetwarzanie danych osobowych jest całkowicie dobrowolne,
                        jednakże brak jej udzielenia uniemożliwia zarejestrowanie się na stronie internetowej
                        oraz dokonywanie zakupów, za pośrednictwem strony internetowej.
                    </li>
                </ul>

                <h4>V. PRAWA PRZYSŁUGUJĄCE UŻYTKOWNIKOWI</h4>
                <ul>
                    <li>
                        1. Użytkownik może w każdej chwili zażądać od administratora informacji o zakresie przetwarzania danych osobowych.
                    </li>
                    <li>
                        2. Użytkownik może w każdej chwili zażądać poprawienia bądź sprostowania
                        swoich danych osobowych. Użytkownik może to również zrobić samodzielnie,
                        po zalogowaniu się na swoje konto.
                    </li>
                    <li>
                        3. Użytkownik może w każdej chwili wycofać swoją zgodę na przetwarzanie
                        jego danych osobowych, bez podawania przyczyny. Żądanie nieprzetwarzania
                        danych może dotyczyć wskazanego przez Użytkownika konkretnego celu przetwarzania
                        np. wycofanie zgody na otrzymywanie informacji handlowych bądź dotyczyć wszystkich
                        celów przetwarzania danych. Wycofanie zgody co do wszystkich celów przetwarzania spowoduje,
                        że konto Użytkownika zostanie dezaktywowane. Wycofanie zgody nie wpłynie na już dokonane
                        czynności. Użytkownik nie będzie miał dostępu do konta bez ponownego udzielenia zgody.
                    </li>
                    <li>
                        4. Użytkownik może w każdej chwili żądać, bez podawania przyczyny, aby administrator usunął Jego dane.
                        Żądanie usunięcia danych nie wpłynie na dotychczas dokonane czynności.
                        Usunięcie danych oznacza jednoczesne usunięcie konta Użytkownika,
                        wraz ze wszystkimi zapisanymi i przetwarzanymi do tej pory przez administratora danymi osobowymi.
                    </li>
                    <li>
                        5. Użytkownik może w każdej chwili wyrazić sprzeciw przeciwko przetwarzaniu danych osobowych,
                        zarówno w zakresie wszystkich przetwarzanych przez administratora danych osobowych
                        Użytkownika, jak również jedynie w ograniczonym zakresie
                        np. co do przetwarzania danych w konkretnie wskazanym celu.
                        Sprzeciw nie wpłynie na dotychczas dokonane czynności. Wniesienie sprzeciwu spowoduje
                        usunięcie konta Użytkownika, wraz ze wszystkimi zapisanymi i przetwarzanymi do tej pory,
                        przez administratora, danymi osobowymi.
                    </li>
                    <li>
                        6. Administrator informuje Użytkownika o podjętych działaniach,
                        przed upływem miesiąca od otrzymania jednego z żądań wymienionych w poprzednich punktach.
                    </li>
                </ul>

                <h4>VI. ODBIORCY DANYCH OSOBOWYCH</h4>
                <ul>
                    <li>
                        1. Administrator danych osobowych może ujawnić dane osobowe użytkowników wyłącznie w przypadkach,
                        w których jest to niezbędne do realizacji celów, dla których dane te zostały zebrane.
                        Odbiorcami danych osobowych mogą być:
                        <ul>
                            <li>
                                a. Dostawcy usług zewnętrznych, którzy pomagają w przetwarzaniu danych,
                                takich jak dostawcy usług hostingowych, dostawcy usług płatniczych (np. system przelewy24),
                                dostawcy usług mailingowych itp.
                            </li>
                            <li>
                                b. Władze publiczne, organy ścigania lub inne podmioty, jeśli jest to wymagane przez prawo
                                lub w celu ochrony praw i interesów administratora danych lub użytkowników.
                            </li>
                        </ul>
                    </li>
                    <li>
                        2. Administrator danych osobowych podejmuje odpowiednie środki, aby zapewnić,
                        że wszyscy odbiorcy danych osobowych przestrzegają zasad ochrony prywatności i bezpieczeństwa danych.
                    </li>
                </ul>

                <h4>VII. POWIERZENIE PRZETWARZANIA DANYCH INNYM PODMIOTOM</h4>
                <ul>
                    <li>
                        1. Administrator może powierzać przetwarzanie danych osobowych podmiotom współpracującym z administratorem,
                        w zakresie niezbędnym dla realizacji transakcji np. w celu przygotowania zamówionego towaru
                        oraz dostarczania przesyłek lub przekazywania informacji handlowych,
                        pochodzących od administratora
                        (ostatnie dotyczy Użytkowników, którzy wyrazili zgodę na otrzymywanie informacji handlowych).
                    </li>
                    <li>
                        2. Poza celami, wskazanymi w niniejszej Polityce Prywatności, dane osobowe Użytkowników,
                        nie będą w żaden sposób udostępniane osobom trzecim, ani przekazywane innym podmiotom,
                        w celu przesyłania materiałów marketingowych tych osób trzecich.
                    </li>
                    <li>
                        3. Dane osobowe Użytkowników strony
                        internetowej nie są przekazywane poza obszar Unii Europejskiej.
                    </li>
                    <li>
                        4. Niniejsza Polityka Prywatności jest zgodna z przepisami wynikającymi
                        z art. 13 ust. 1 i ust. 2 rozporządzenia RODO.
                    </li>
                </ul>

                <h4>VIII. PLIKI COOKIES</h4>
                <ul>
                    <li>
                        1. Strona internetowa używa plików cookies (ciasteczka) lub podobną technologię
                        (dalej łącznie nazywane: &quot;cookies&quot;) do zbierania informacji o dostępie Użytkownika do
                        strony internetowej (np. za pomocą komputera lub smartfonu) oraz jego preferencjach.
                        Są one wykorzystywane m.in. w celach reklamowych i statystycznych oraz w celu dostosowania
                        strony internetowej do indywidualnych potrzeb Użytkownika.
                    </li>
                    <li>
                        2. Pliki cookies to fragmenty informacji, które zawierają unikalny kod referencyjny,
                        który strona internetowa przesyła na urządzenie Użytkownika, w celu przechowywania,
                        a czasem śledzenia informacji, dotyczących używanego urządzenia.
                        Zwykle nie pozwalają one zidentyfikować osoby Użytkownika.
                        Ich głównym zadaniem jest lepsze dopasowanie strony internetowej do Użytkownika.
                    </li>
                    <li>
                        3. Niektóre z plików cookies, występujące na stronie internetowej,
                        są dostępne tylko przez czas trwania danej sesji internetowej i wygasają po zamknięciu
                        przeglądarki. Inne pliki cookies służą do zapamiętywania Użytkownika,
                        który po powrocie na stronę internetową, jest na niej rozpoznawany.
                        Są one wówczas zachowywane przed dłuższy czas.
                    </li>
                    <li>
                        4. Wszystkie pliki cookies, występujące na stronie internetowej,
                        są ustalane przez administratora.
                    </li>
                    <li>
                        5. Wszystkie pliki cookies, używane przez niniejszą stronę internetową,
                        są zgodne z obowiązującym prawem Unii Europejskiej.
                    </li>
                    <li>
                        6. Większość Użytkowników i niektórych przeglądarek mobilnych
                        automatycznie akceptuje pliki cookies. Jeżeli Użytkownik nie zmieni ustawień,
                        pliki cookies zostaną zapisane w pamięci urządzenia.
                    </li>
                    <li>
                        7. Użytkownik może zmienić preferencje, dotyczące akceptacji plików cookies lub zmienić przeglądarkę,
                        aby móc otrzymać za każdym razem stosowne powiadomienie, gdy funkcja cookies jest ustawiona.
                        Aby zmienić ustawienia akceptacji cookies, należy dostosować ustawienia w przeglądarce.
                    </li>
                    <li>
                        8. Warto pamiętać, że blokowanie lub usuwanie plików cookies może uniemożliwić
                        pełne korzystanie ze strony internetowej.
                    </li>
                    <li>
                        10. Pliki cookies będą wykorzystywane do niezbędnego zarządzania sesją, w tym:
                        <ul>
                            <li>
                                a. Tworzenia specjalnej sesji logowania dla Użytkownika strony internetowej,
                                aby strona zapamiętała, że Użytkownik jest zalogowany, a jego żądania były
                                dostarczane w sposób skuteczny, bezpieczny i spójny;
                            </li>
                            <li>
                                b. Rozpoznawania Użytkownika, który już wcześniej odwiedził stronę internetową,
                                co pozwala na identyfikację liczby unikalnych użytkowników, którzy skorzystali
                                z serwisu i pozwala upewnić się co do wystarczającej pojemności serwisu dla liczby
                                nowych użytkowników;
                            </li>
                            <li>
                                c. Rozpoznawania, czy osoba odwiedzająca stronę internetową jest zarejestrowana na stronie internetowej;
                            </li>
                            <li>
                                d. Rejestrowanie informacji z urządzenia Użytkownika, w tym: pliki cookies,
                                adres IP i informacje o używanej przeglądarce, w celu możliwości diagnozowania problemów,
                                administrowania i śledzenia Użytkowania witryny;
                            </li>
                            <li>
                                e. Dostosowywania elementów układu szaty graficznej lub zawartości strony internetowej;
                            </li>
                            <li>
                                f. Zbierania informacji statystycznych o tym, jak Użytkownik korzysta ze strony,
                                w celu możliwości ulepszania witryny i stwierdzenia, które zakresy strony internetowej
                                są najbardziej popularne dla Użytkowników.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default PrivatePolicyPage;
