import {
    DELETE_RECIPES,
    GET_FILTERED_RECIPES,
    GET_RECIPE_BY_ID,
    GET_RECIPES_BY_IDS,
} from '../../constants/actionTypes';

const initialState = [];

const recipesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FILTERED_RECIPES: {
            const { data } = action.data;
            const recipes = data.map((item) => ({ ...item }));

            return recipes;
        }
        case DELETE_RECIPES:
            return initialState;
        case GET_RECIPE_BY_ID: {
            const recipe = action.data;
            const oldState = [...state];
            const isRecipeInStore = oldState.find((item) => {
                if (item.id === recipe.id) {
                    return true;
                }

                return false;
            });

            if (!isRecipeInStore) {
                return [...state, recipe];
            }

            return oldState;
        }
        case GET_RECIPES_BY_IDS: {
            return action.data;
        }
        default:
            return state;
    }
};

export default recipesReducer;
