import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ProductItem from '../components/PriceList/ProductItem';
import './PriceListPage.css';

function PriceListPage() {
    const products = useSelector((state) => state.products);
    const productTile = useMemo(() => (
        products.map((item) => <ProductItem product={item} key={item.id} />)
    ), [products]);

    return (
        <div className="container home-page-container">
            <div className="price-list-page list-page-container">
                {productTile}
            </div>
        </div>
    );
}

export default PriceListPage;
