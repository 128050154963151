import {
    GET_ALL_CATEGORIES,
} from '../../constants/actionTypes';

const initialState = [];

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORIES: {
            const { categories } = action.data;

            return categories;
        }
        default:
            return state;
    }
};

export default categoryReducer;
