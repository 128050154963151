import {
    GET_USER_DATA,
} from '../../constants/actionTypes';

const initialState = {};

const userDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DATA: {
            return action.data;
        }
        default:
            return state;
    }
};

export default userDataReducer;
