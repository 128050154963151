import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addOrDeleteFavorite, getUserFavorites } from '../../redux/actions/favouriteAction';
import { addToastMessage } from '../../redux/actions/toastAction';
import IconButton from '../Buttons/IconButton';
import ButtonInline from '../Buttons/ButtonInline';

function FavoriteButton({ recipeId, iconButton = true }) {
    const dispatch = useDispatch();
    const favorites = useSelector((state) => state.favourites);
    const recipes = useSelector((state) => state.recipes);
    const { id } = useSelector((state) => state.auth);

    const { recipe, isFavorite } = useMemo(() => {
        const recipeToReturn = recipes.find((item) => (item.id) === recipeId);
        const isRecipeFavourite = favorites.find((item) => item.id === recipeId);

        return { recipe: recipeToReturn, isFavorite: isRecipeFavourite };
    }, [favorites, recipeId, recipes]);

    const [isFetching, setIsFetching] = useState(false);

    const handleAddToFavorite = useCallback((e) => {
        e.preventDefault();

        setIsFetching(true);
        dispatch(addOrDeleteFavorite(id, recipeId)).then(() => {
            setIsFetching(false);
        });

        dispatch(addToastMessage(`${recipe.title} został dodany do ulubionych`));
    }, [dispatch, recipe?.title, recipeId, id]);

    const handleRemoveToFavorite = useCallback((e) => {
        e.preventDefault(e);
        setIsFetching(true);

        dispatch(addOrDeleteFavorite(id, recipeId)).then(() => {
            setIsFetching(false);
        });

        dispatch(addToastMessage(`${recipe.title} został usunięty z ulubionych`));
    }, [dispatch, recipe?.title, recipeId, id]);

    return (
        <Choose>
            <When condition={isFavorite && iconButton}>
                <IconButton
                  className="is-favorite"
                  icon={<MdFavorite className="fav-icon" />}
                  onClick={handleRemoveToFavorite}
                  disabled={isFetching}
                  dataTest="delete-favourite-icon"
                />
            </When>
            <When condition={!isFavorite && iconButton}>
                <IconButton
                  className="fav-icon"
                  icon={<MdFavoriteBorder className="fav-icon" />}
                  onClick={handleAddToFavorite}
                  disabled={isFetching}
                  dataTest="add-favourite-icon"
                />
            </When>
            <When condition={isFavorite && !iconButton}>
                <ButtonInline
                  className="btn-primary btn-large"
                  onClick={handleRemoveToFavorite}
                  disabled={isFetching}
                  text="Usuń z ulubionych"
                  dataTest="delete-favourite-button"
                />
            </When>
            <When condition={!isFavorite && !iconButton}>
                <ButtonInline
                  className="btn-primary btn-large"
                  onClick={handleAddToFavorite}
                  disabled={isFetching}
                  text="Dodaj do ulubionych"
                  dataTest="add-favourite-button"
                />
            </When>
        </Choose>
    );
}

export default FavoriteButton;
