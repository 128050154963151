import React, {
    useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { getAllAgreements } from '../../redux/actions/agreementsAction';
import { signUp } from '../../redux/actions/authAction';
import InputWithLabel from '../input/InputWithLabel';
import AgreementsList from './AgreementsList';
import ButtonInline from '../Buttons/ButtonInline';
import ErrorBar from '../snackbar/ErrorBar';
import FormCard from '../FormCard/FormCard';

function SignUpForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const captchaRef = useRef(null);

    const agreements = useSelector((state) => state.agreements);

    const initialState = {
        login: '',
        email: '',
        replyPassword: '',
        password: '',
        agreements: [],
    };

    const [formData, setFormData] = useState(initialState);
    const [error, setError] = useState(null);
    const [agreementsError, setAgreementsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const handleChangeInput = useCallback((e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }, [formData]);

    const handleRegister = useCallback((e) => {
        e.preventDefault();

        const token = captchaRef.current.getValue();

        setIsFetching(true);
        setAgreementsError(false);

        const isUncheckRequireAgreements = formData.agreements.some((item) => item.isRequire && !item.isMarked);

        if (isUncheckRequireAgreements) {
            setAgreementsError(true);
            setIsFetching(false);

            return;
        }

        dispatch(signUp(formData, navigate, token))
            .then((res) => {
                if (res.status !== 200) {
                    setError(res.data);
                }
                setIsFetching(false);
                captchaRef.current.reset();
            });
    }, [dispatch, formData, navigate]);

    const handlerOnClickAgreement = useCallback((agreement) => {
        setFormData((prev) => {
            const oldState = { ...prev };
            const newAgreements = oldState.agreements.map((item) => {
                if (item.agreement === agreement.id) {
                    return { ...item, isMarked: !item.isMarked };
                }

                return item;
            });

            return { ...prev, agreements: newAgreements };
        });
    }, []);

    useEffect(() => {
        if (!agreements.length) {
            dispatch(getAllAgreements());
        }

        const agreementInitialState = agreements.map((item) => (
            { agreement: item.id, isMarked: false, isRequire: item.isRequire }
        ));

        setFormData((prev) => ({ ...prev, agreements: agreementInitialState }));
    }, [agreements, dispatch]);

    return (
        <FormCard title="Zarejestruj się" onSubmit={handleRegister}>
            <If condition={agreementsError}>
                <ErrorBar text="Zaznacz wymagane zgody aby kontynuować" />
            </If>
            <InputWithLabel
              className="label-auth"
              id="label-login"
              name="login"
              type="text"
              placeholder="Login"
              value={formData.login}
              onChange={handleChangeInput}
              error={error}
              dataTest="login-input"
            />
            <InputWithLabel
              className="label-auth"
              id="label-email"
              name="email"
              type="text"
              placeholder="Email"
              value={formData.email}
              onChange={handleChangeInput}
              error={error}
              dataTest="email-input"
            />
            <InputWithLabel
              className="label-auth"
              id="label-password"
              name="password"
              type="password"
              placeholder="Hasło"
              value={formData.password}
              onChange={handleChangeInput}
              error={error}
              dataTest="password-input"
            />
            <InputWithLabel
              className="label-auth"
              id="label-reply-password"
              name="replyPassword"
              type="password"
              placeholder="Powtórz hasło"
              value={formData.replyPassword}
              onChange={handleChangeInput}
              error={error}
              dataTest="reply-password-input"
            />
            <AgreementsList agreements={agreements} onChange={handlerOnClickAgreement} />
            <If condition={error?.input === 'reCAPTCHA'}>
                <ErrorBar text={error.message} />
            </If>
            <ReCAPTCHA
              sitekey={process.env.SITE_KEY_RE_CAPTCHA}
              ref={captchaRef}
            />
            <ButtonInline
              type="submit"
              className="btn-primary btn-large btn-max-width"
              text="Zarejestruj się"
              disabled={
                        !formData.login
                        || !formData.email
                        || !formData.password
                        || !formData.replyPassword
                    }
              isLoading={isFetching}
              dataTest="submit-register-form-button"
            />
            <div className="auth-form-bottom-section typo-normal">
                <p className="registration-paragraph">
                    Masz konto?
                    {' '}
                    <NavLink
                      className="registration-link"
                      to="/signin"
                    >
                        Wróć do logowania
                    </NavLink>
                </p>
            </div>
        </FormCard>
    );
}

export default SignUpForm;
