import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import ButtonInline from '../Buttons/ButtonInline';
import { registerTransaction } from '../../api/fetchApi';
import './PriceList.css';

function ProductItem({ product }) {
    const { id: userId } = useSelector((state) => state.auth);
    const { hasUserActiveProduct, dateExpirationLastTransaction } = useSelector((state) => state.userData);
    const [isFetching, setIsFetching] = useState(false);

    const handleBuy = async () => {
        setIsFetching(true);
        const { data } = await registerTransaction(userId, product.id);
        window.location.href = data.link;
    };

    return (
        <div className="product-container">
            <div className="product-left-section">
                <h2 className="product-title">
                    {product.name}
                </h2>
                <div className="product-description typo-normal">
                    {product.description}
                </div>
            </div>
            <div className="product-right-section">
                <Choose>
                    <When condition={hasUserActiveProduct}>
                        <p className="typo-normal active-text">Aktywny</p>
                        <p className="typo-small color-white">
                            Do:
                            {' '}
                            {format(dateExpirationLastTransaction, 'dd MMM yyyy', { locale: pl })}
                        </p>
                    </When>
                    <Otherwise>
                        <div className="product-right-description">
                            <div className="product-duration typo-small">
                                Kup dostęp do produktu na
                                {' '}
                                {product.durationInDays}
                                {' '}
                                dni
                                {' '}
                                za
                            </div>
                            <div className="product-price typo-normal">
                                {product.price}
                                {' '}
                                zł
                            </div>
                        </div>
                        <ButtonInline
                          className="btn-primary"
                          text="Kup"
                          onClick={handleBuy}
                          isLoading={isFetching}
                          dataTest="buy-button"
                        />
                    </Otherwise>
                </Choose>
            </div>
        </div>
    );
}

export default ProductItem;
