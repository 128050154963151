import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { changeIngredientState } from '../../redux/actions/shoppingListAction';
import Checkbox from '../Buttons/Checkbox';

function ShoppingListIngredients({ list, showAll }) {
    const dispatch = useDispatch();
    const [showAllIngredients, setShowAllIngredients] = useState(showAll);

    const handleChangeIngredientState = useCallback(({ listId, itemId }) => {
        dispatch(changeIngredientState(listId, itemId));
    }, [dispatch]);

    const handleToggleShowAllIngredients = useCallback((e) => {
        e.stopPropagation();
        setShowAllIngredients((prev) => !prev);
    }, []);

    const ingredientsList = useMemo(() => (
        list.ingredients.map((item) => (
            <li key={item.id}>
                <Checkbox
                  label={(
                    `${item.name} 
                    ${item.amount} 
                    ${item.amount > 1 ? item.pluralPrefix : item.prefix}`
                  )}
                  checked={item.isSelected}
                  onChange={() => handleChangeIngredientState({ listId: list.id, itemId: item.id })}
                  dataTest={`${item.name}-ingredient-checkbox`}
                />
            </li>
        ))
    ), [handleChangeIngredientState, list.id, list.ingredients]);

    const ingredientsToDisplay = useMemo(() => (
        <div>
            {showAllIngredients ? ingredientsList : ingredientsList.slice(0, 3)}
            <If condition={ingredientsList.length > 3}>
                <div className="show-all-ingredients typo-small">
                    <button
                      className="btn-link"
                      type="button"
                      onClick={handleToggleShowAllIngredients}
                    >
                        {showAllIngredients ? 'zwiń' : 'rozwiń'}
                        {showAllIngredients ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                </div>
            </If>
        </div>
    ), [handleToggleShowAllIngredients, ingredientsList, showAllIngredients]);

    return (
        <If condition={list}>
            <div className="grid-shopping-list-ingredients-container">
                <ul>
                    {ingredientsToDisplay}
                </ul>
            </div>
        </If>
    );
}

export default ShoppingListIngredients;
