import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import './Loader.css';

function LoaderOnAllSite() {
    return (
        <div className="loader-container">
            <TailSpin
              height="80"
              width="80"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible
            />
        </div>
    );
}

export default LoaderOnAllSite;
