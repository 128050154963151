import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthData } from '../redux/actions/authAction';
import { getUserFavorites } from '../redux/actions/favouriteAction';
import { getAllProducts } from '../redux/actions/ProductAction';

function LoadData() {
    const dispatch = useDispatch();
    useEffect(() => {
        const userDataFromLocalStorage = JSON.parse(localStorage.getItem('profile')) || {};

        dispatch(getAllProducts());

        if (Object.keys(userDataFromLocalStorage).length) {
            const { data: userData } = userDataFromLocalStorage;
            dispatch(getUserFavorites(userData?.id));
            dispatch(setAuthData());
        }
    }, [dispatch]);

    return null;
}

export default LoadData;
