import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import './IncreaseDecreaseInput.css';

function IncreaseDecreaseInput({
    className, value, onChange,
}) {
    const [inputValue, setInputValue] = useState(value * 1);

    const handleDecrease = () => {
        let newValue;
        if (inputValue > 0 && inputValue <= 9) {
            newValue = inputValue - 1;
        } else if (inputValue >= 10 && inputValue <= 99) {
            newValue = inputValue - 10;
        } else if (inputValue >= 100) {
            newValue = inputValue - 100;
        } else {
            newValue = inputValue;
        }

        setInputValue(Math.max(newValue, 1));
        onChange(Math.max(newValue, 1));
    };

    const handleIncrease = () => {
        let newValue;
        if (inputValue <= 9) {
            newValue = inputValue + 1;
        } else if (inputValue >= 10 && inputValue <= 99) {
            newValue = inputValue + 10;
        } else if (inputValue >= 100 && inputValue <= 9999) {
            newValue = inputValue + 100;
        } else {
            newValue = inputValue;
        }

        setInputValue(Math.min(newValue, 9999));
        onChange(Math.min(newValue, 9999));
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value.slice(0, 4);
        if (!isNaN(newValue) && newValue >= 0) {
            setInputValue(newValue * 1);
            onChange(newValue * 1);
        }
    };

    return (
        <div
          className={`increase-decrease-input-container ${className || ''}`}
          data-test="increase-decrease-input"
        >
            <AiOutlineMinus className="increase-decrease-icon" onClick={handleDecrease} data-test="decrease-icon" />
            <input
              type="number"
              maxLength="4"
              min={1}
              value={inputValue}
              onChange={handleInputChange}
            />
            <AiOutlinePlus className="increase-decrease-icon" onClick={handleIncrease} data-test="increase-icon" />
        </div>
    );
}

export default IncreaseDecreaseInput;
