import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai';
import { SiYoutube, SiTiktok } from 'react-icons/si';
import { getAllPeople } from '../../redux/actions/peopleAction';
import LoaderOnAllSite from '../Loader/LoaderOnAllSite';
import BackHistoryArrow from '../BackHistoryArrow/BackHistoryArrow';

function PersonDetails() {
    const dispatch = useDispatch();

    const { id } = useParams();
    const { people } = useSelector((state) => state);

    const personData = useMemo(() => (
        people?.find((item) => item.id === id)
    ), [id, people]);

    const {
        facebook, youtube, instagram, tiktok,
    } = personData?.socialMediaLinks || {};

    useEffect(() => {
        if (!people.length) {
            dispatch(getAllPeople());
        }
    }, [dispatch, people.length]);

    return (
        <div>
            <BackHistoryArrow navigateTo="/" />
            <Choose>
                <When condition={people.length}>
                    <div className="person-details-page">
                        <div className="person-poster-container">
                            <img className="person-image" src={personData.image} alt={personData.title} />
                            <If condition={instagram || youtube || facebook}>
                                <div className="social-media-container">
                                    <If condition={facebook}>
                                        <a
                                          className="social-media-poster-icon"
                                          href={facebook}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                            <AiFillFacebook size={35} />
                                        </a>
                                    </If>
                                    <If condition={instagram}>
                                        <a
                                          className="social-media-poster-icon"
                                          href={instagram}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                            <AiFillInstagram size={35} />
                                        </a>
                                    </If>
                                    <If condition={youtube}>
                                        <a
                                          className="social-media-poster-icon"
                                          href={youtube}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                            <SiYoutube size={35} />
                                        </a>
                                    </If>
                                    <If condition={tiktok}>
                                        <a
                                          className="social-media-poster-icon"
                                          href={tiktok}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                            <SiTiktok size={35} />
                                        </a>
                                    </If>
                                </div>
                            </If>
                        </div>
                        <div className="person-page-metadata-container">
                            <h2 className="person-name">{personData.name}</h2>
                            <div className="person-description typo-normal">{personData.description}</div>
                        </div>
                    </div>
                </When>
                <Otherwise>
                    <LoaderOnAllSite />
                </Otherwise>
            </Choose>
        </div>
    );
}

export default PersonDetails;
