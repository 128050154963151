import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import ListSection from '../PageSections/ListSection';
import BannerItem from './BannerItem';
import './BannerItem.css';

function HomePageBanner() {
    const banners = useSelector((state) => state.banners);

    const bannersItems = useMemo(() => (
        banners.map((banner) => (
            <BannerItem
              title={banner.title}
              image={banner.image}
              description={banner.description}
            />
        ))
    ), [banners]);

    return (
        <ListSection
          style={{ margin: 'auto' }}
        >
            <Slider
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              dots
              autoplay
              autoplaySpeed={5000}
            >
                {bannersItems}
            </Slider>
        </ListSection>
    );
}

export default HomePageBanner;
