import * as api from '../../api/fetchApi';
import { ADD_OR_DELETE_FAVORITE, GET_USER_FAVORITE_LIST } from '../../constants/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const getUserFavorites = (userId) => async (dispatch) => {
    try {
        const { data } = await api.getUserFavoriteList(userId);

        dispatch({
            type: GET_USER_FAVORITE_LIST,
            data,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const addOrDeleteFavorite = (userId, recipeId) => async (dispatch) => {
    try {
        const { data } = await api.addOrDeleteUserFavorite(userId, recipeId);

        dispatch({
            type: ADD_OR_DELETE_FAVORITE,
            data: {
                favourites: data,
                id: recipeId,
            },
        });
    } catch (error) {
        console.log(error);
    }
};
