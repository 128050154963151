export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';

export const GET_FILTERED_RECIPES = 'GET_FILTERED_RECIPES';
export const DELETE_RECIPES = 'DELETE_RECIPES';
export const GET_RECIPE_BY_ID = 'GET_RECIPE_BY_ID';
export const GET_RECIPES_BY_IDS = 'GET_RECIPES_BY_IDS';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const ADD_TOAST_ERROR = 'ADD_TOAST_ERROR';
export const REMOVE_ERROR_TOAST = 'REMOVE_ERROR_TOAST';

export const CREATE_SHOPPING_LIST = 'CREATE_SHOPPING_LIST';
export const CLEAR_SHOPPING_LIST = 'CLEAR_SHOPPING_LIST';
export const GET_USER_SHOPPING_LIST = 'GET_USER_SHOPPING_LIST';
export const CHANGE_INGREDIENT_STATE = 'CHANGE_INGREDIENT_STATE';
export const EDIT_USER_SHOPPING_LITS = 'EDIT_USER_SHOPPING_LITS';
export const DELETE_USER_SHOPPING_LITS = 'DELETE_USER_SHOPPING_LITS';

export const GET_PAGINATION_DATA = 'GET_PAGINATION_DATA';
export const CLEAR_PAGINATION_DATA = 'CLEAR_PAGINATION_DATA';

export const GET_USER_FAVORITE_LIST = 'GET_USER_FAVORITE_LIST';
export const ADD_OR_DELETE_FAVORITE = 'ADD_OR_DELETE_FAVORITE';
export const CLEAR_FAVOURITES = 'CLEAR_FAVOURITES';

export const GET_ALL_PEOPLE = 'GET_ALL_PEOPLE';

export const GET_ALL_AGREEMENTS = 'GET_ALL_AGREEMENTS';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';

export const GET_BANNERS = 'GET_BANNERS';

export const GET_USER_DATA = 'GET_USER_DATA';
