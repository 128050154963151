import { GET_BANNERS } from '../../constants/actionTypes';

const initialState = [];

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BANNERS: {
            return action.data;
        }
        default:
            return state;
    }
};

export default bannerReducer;
