import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import './VerifyEmailPage.css';

function VerifyEmailPage() {
    const navigate = useNavigate();
    const { isActive } = useSelector((state) => state.auth);

    if (isActive) {
        navigate('/');
    }

    return (
        <div className="container">
            <div className="verify-email-page">
                <h1>Zweryfikuj adres email</h1>
                <p className="typo-normal">
                    Na twój adres email została wysłana wiadomość z linkiem aktywacyjnym.
                    Kliknij w link aby dokończyć rejestrację.
                </p>
            </div>

        </div>
    );
}

export default VerifyEmailPage;
