import React, {
    forwardRef, useCallback, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { TbDots } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import withClickOutside from '../Navbar/withClickOutside';
import { deleteShoppingList } from '../../redux/actions/shoppingListAction';
import { addToastErrorMessage, addToastMessage } from '../../redux/actions/toastAction';

const ShoppingListContextMenu = forwardRef(({ open, setOpen }, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { shoppingLists } = useSelector((state) => state.shoppingList);
    const { hasUserActiveProduct } = useSelector((state) => state.userData);

    const list = useMemo(() => (
        shoppingLists?.find((item) => item.id === id)
    ), [id, shoppingLists]);

    const [isFetching, setIsFetching] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, [setOpen]);

    const handleDeleteList = useCallback((e) => {
        setIsFetching(true);
        e.preventDefault();
        dispatch(deleteShoppingList(id))
            .then(() => {
                navigate('/lists');
                dispatch(addToastMessage('Lista została usunięta'));
                setIsFetching(false);
            });
    }, [dispatch, id, navigate]);

    const handleEditList = useCallback((e) => {
        e.preventDefault();
        navigate(`/edit/shoppingList/${id}`);
    }, [id, navigate]);

    const handleBlockedRelatedList = useCallback(() => {
        dispatch(addToastErrorMessage('Kup pakiet aby odblokować funkcję'));
    }, [dispatch]);

    return (
        <section ref={ref}>
            <div
              aria-hidden="true"
              onClick={handleToggle}
              className="button-toggle-options"
            >
                <TbDots className={`icon context-menu-icon shopping-list-context-menu-icon${open ? ' open' : ''}`} />
            </div>
            <div className={`shopping-list context-menu-dropdown ${open ? 'open' : 'hidden'}`}>
                <div className="shopping-list context-menu-items">
                    <div
                      className="typo-small shopping-list context-menu-item"
                      onClick={handleEditList}
                      aria-hidden
                    >
                        Edytuj
                    </div>
                    <Choose>
                        <When condition={list?.relatedRecipe?.length && hasUserActiveProduct}>
                            <div
                              className="typo-small shopping-list context-menu-item"
                              onClick={() => navigate(`/edit/shoppingList/recipes/${id}`)}
                              aria-hidden
                            >
                                Powiązane przepisy
                            </div>
                        </When>
                        <When condition={list?.relatedRecipe && !hasUserActiveProduct}>
                            <div
                              className="typo-small shopping-list context-menu-item item-blocked"
                              onClick={handleBlockedRelatedList}
                              aria-hidden
                            >
                                Powiązane przepisy
                            </div>
                        </When>
                    </Choose>
                    <div
                      className="typo-small shopping-list context-menu-item warning"
                      onClick={isFetching ? null : handleDeleteList}
                      aria-hidden
                    >
                        Usuń
                    </div>
                </div>
            </div>
        </section>
    );
});

export default withClickOutside(ShoppingListContextMenu);
