import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
    editUserShoppingList,
    getUserShoppingList,
} from '../../redux/actions/shoppingListAction';
import BackHistoryArrow from '../BackHistoryArrow/BackHistoryArrow';
import ButtonInline from '../Buttons/ButtonInline';
import SelectedIngredientsList from '../CreateShoppingList/SelectedIngredientsList';
import InputWithLabel from '../input/InputWithLabel';
import * as api from '../../api/fetchApi';

function EditShoppingList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const initialState = {
        title: '',
        ingredients: '',
    };

    const { shoppingLists } = useSelector((store) => store.shoppingList);
    const { id: userId } = useSelector((state) => state.auth);

    const [isFetching, setIsFetching] = useState(false);
    const [list, setList] = useState();
    const [formData, setFormData] = useState(initialState);
    const [searchIngredientsOptions, setSearchIngredientsOptions] = useState('');
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [reqTimeout, setRequestTimeout] = useState(null);

    const handleSaveEditedList = (e) => {
        e.preventDefault();
        setIsFetching(true);
        dispatch(editUserShoppingList({
            id: list.id,
            ingredients: selectedIngredients.map((item) => ({
                id: item.id,
                amount: item.amount,
                isSelected: item.isSelected,
            })),
            title: formData.title,
        })).then(() => {
            setIsFetching(false);
            navigate(`/shoppingList/${id}`);
        });
    };

    const handleCancelButton = (e) => {
        e.preventDefault();
        navigate(`/shoppingList/${id}`);
    };

    const handleChangeInput = useCallback((e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        const shouldSearchIngredients = (e.target.name === 'ingredients');
        if (shouldSearchIngredients) {
            if (reqTimeout) {
                clearTimeout(reqTimeout);
            }

            setRequestTimeout(
                setTimeout(async () => {
                    const { data } = await api.getSearchIngredients({
                        searchQuery: e.target.value,
                        limit: 5,
                    });
                    setSearchIngredientsOptions(data.ingredients);
                }, 500),
            );
        }
    }, [formData, reqTimeout]);

    const handleSelectIngredient = useCallback((ingredient) => {
        setSelectedIngredients((prev) => {
            const isOnList = prev.find((item) => item.name === ingredient.name);

            if (isOnList) {
                return prev;
            }

            return [...prev, { ...ingredient, amount: 1 }];
        });
        setFormData((prev) => ({ ...prev, ingredients: '' }));
        setSearchIngredientsOptions([]);
    }, []);

    const handleAddIngredientValue = useCallback((value, ingredientId) => {
        setSelectedIngredients((prev) => {
            const prevState = [...prev];
            const newState = prevState.map((item) => {
                if (item.id === ingredientId) {
                    return { ...item, amount: Math.min(value * 1, 999), isSelected: false };
                }

                return item;
            });

            return newState;
        });
    }, []);

    const handleRemoveIngredient = useCallback((name) => {
        setSelectedIngredients((prev) => prev.filter((item) => item.name !== name));
    }, []);

    useEffect(() => {
        if (!shoppingLists.length && userId) {
            dispatch(getUserShoppingList(userId));
        }
    }, [dispatch, shoppingLists.length, userId]);

    useEffect(() => {
        if (!list && shoppingLists.length) {
            const listToSet = shoppingLists.find((item) => item.id === id);
            setList(listToSet);
            setFormData((prev) => ({ ...prev, title: listToSet.title }));
            setSelectedIngredients(listToSet.ingredients);
        }
    }, [id, list, shoppingLists]);

    return (
        <div className="container home-page-container">
            <If condition={list}>
                <div className="back-arrow-container">
                    <BackHistoryArrow />
                </div>
                <div className="list-page-main-layout">
                    <div className="create-list-content edit-list">
                        <h2 className="edit-list-title">Edytuj listę</h2>
                        <InputWithLabel
                          className="typo-small"
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Nazwa listy"
                          value={formData.title}
                          onChange={handleChangeInput}
                          dataTest="list-title-input"
                        />
                        <div className="add-ingredients-container edit-list">
                            <div className="ingredients-list-container">
                                <InputWithLabel
                                  className="typo-small"
                                  id="Ingredients"
                                  name="ingredients"
                                  type="text"
                                  placeholder="Wyszukaj składnik"
                                  value={formData.ingredients}
                                  onChange={handleChangeInput}
                                  dataTest="search-ingredients-input"
                                >
                                    <If condition={searchIngredientsOptions.length}>
                                        {searchIngredientsOptions.map((item) => (
                                            <div
                                              key={item.id}
                                              onClick={() => handleSelectIngredient(item)}
                                              aria-hidden
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </If>
                                </InputWithLabel>
                            </div>
                            <div className="selected-ingredients-list">
                                <SelectedIngredientsList
                                  className="edit-list"
                                  ingredients={selectedIngredients}
                                  setIngredientValue={handleAddIngredientValue}
                                  removeIngredient={handleRemoveIngredient}
                                />
                            </div>
                        </div>

                        <div className="edit-shopping-list-bnt-section">
                            <ButtonInline
                              text="Zapisz zmiany"
                              className="btn-primary btn-large"
                              onClick={handleSaveEditedList}
                              isLoading={isFetching}
                              dataTest="save-button"
                            />

                            <ButtonInline
                              text="Anuluj"
                              className="btn-primary btn-large"
                              onClick={handleCancelButton}
                              dataTest="cancel-button"
                            />
                        </div>
                    </div>
                </div>
            </If>
        </div>
    );
}

export default EditShoppingList;
