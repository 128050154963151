import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FavouriteButton from '../FavouriteButton/FavouriteButton';
import { RECIPE_LEVELS } from '../../constants/levels';
import './Recipes.css';

function RecipesGridItem({ recipe }) {
    const { id: userId } = useSelector((state) => state.auth);
    const {
        id, title, image, levelId, duration, kcal,
    } = recipe;

    const levelName = useMemo(() => {
        const recipeLevel = RECIPE_LEVELS.find((item) => item.id === levelId);

        return recipeLevel.name;
    }, [levelId]);

    return (
        <div className="grid-item-container">
            <If condition={userId}>
                <div className="grid-item-fav-icon">
                    <FavouriteButton recipeId={id} />
                </div>
            </If>
            <Link
              to={`/recipe/${id}`}
            >
                <div className="grid-item">
                    <img className="item-image" src={image} alt={title} />
                    <h2 className="grid-title">{title}</h2>
                    <div className="grid-item-details typo-small">
                        <If condition={levelId}>
                            <div>
                                {levelName}
                            </div>
                        </If>
                        <If condition={duration}>
                            <div>
                                {duration}
                                {' '}
                                min
                            </div>
                        </If>
                        <If condition={kcal}>
                            <div>
                                {kcal}
                                {' '}
                                kcal
                            </div>
                        </If>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default RecipesGridItem;
