import * as api from '../../api/fetchApi';
import { addToastErrorMessage, addToastMessage } from './toastAction';
import {
    CHANGE_INGREDIENT_STATE,
    CREATE_SHOPPING_LIST,
    DELETE_USER_SHOPPING_LITS,
    EDIT_USER_SHOPPING_LITS,
    GET_USER_SHOPPING_LIST,
} from '../../constants/actionTypes';

export const createShoppingList = (formData) => async (dispatch) => {
    try {
        const response = await api.createShoppingList(formData);

        dispatch({
            type: CREATE_SHOPPING_LIST,
            data: response.data,
        });

        dispatch(addToastMessage('Lista została stworzona'));

        return response;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch(addToastErrorMessage('Został przekroczony limit list'));

        return error.response;
    }
};

export const getUserShoppingList = (userId) => async (dispatch) => {
    try {
        const { data } = await api.getUserShoppingList(userId);

        dispatch({
            type: GET_USER_SHOPPING_LIST,
            data,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const changeIngredientState = (listId, ingredientId) => ({
    type: CHANGE_INGREDIENT_STATE,
    data: {
        listId,
        ingredientId,
    },
});

export const editUserShoppingList = (formData) => async (dispatch) => {
    try {
        const { data } = await api.updateShoppingList(formData);

        dispatch({
            type: EDIT_USER_SHOPPING_LITS,
            data,
        });

        dispatch(addToastMessage('Lista została zapisana'));
    } catch (error) {
        console.log(error);
    }
};

export const deleteShoppingList = (id) => async (dispatch) => {
    try {
        await api.deleteShoppingList(id);

        dispatch({
            type: DELETE_USER_SHOPPING_LITS,
            data: {
                id,
            },
        });
    } catch (error) {
        console.log(error);
    }
};
