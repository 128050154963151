import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { editUserShoppingList, getUserShoppingList } from '../../redux/actions/shoppingListAction';
import ShoppingListIngredients from './ShoppingListIngredients';
import BackHistoryArrow from '../BackHistoryArrow/BackHistoryArrow';
import ButtonInline from '../Buttons/ButtonInline';
import LoaderOnAllSite from '../Loader/LoaderOnAllSite';
import ShoppingListContextMenu from './ShoppingListContextMenu';

function ShoppingList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { shoppingLists } = useSelector((store) => store.shoppingList);
    const { id: userId } = useSelector((state) => state.auth);

    const [isFetching, setIsFetching] = useState({ delete: false, save: false });
    const [list, setList] = useState({});

    const handleSaveList = useCallback((e) => {
        e.preventDefault();
        setIsFetching((prev) => ({ ...prev, save: true }));
        dispatch(editUserShoppingList({ id: list.id, ingredients: list.ingredients }))
            .then(() => {
                setIsFetching((prev) => ({ ...prev, save: false }));
                navigate('/lists');
            });
    }, [dispatch, list.id, list.ingredients, navigate]);

    useEffect(() => {
        if (userId && !shoppingLists.length) {
            dispatch(getUserShoppingList(userId));
        }
    }, [dispatch, shoppingLists.length, userId]);

    useEffect(() => {
        const wantedList = shoppingLists.find((item) => item.id === id);
        setList(wantedList || {});
    }, [id, shoppingLists]);

    return (
        <Choose>
            <When condition={Object.keys(list).length}>
                <div className="container home-page-container">
                    <div className="back-arrow-container">
                        <BackHistoryArrow navigateTo="/lists" />
                    </div>
                    <div className="list-page-main-layout">
                        <div className="list-container">
                            <div className="list-top-section">
                                <h2>
                                    {list?.title}
                                </h2>
                                <ShoppingListContextMenu />
                            </div>
                            <div className="list-main-section typo-normal">
                                <ShoppingListIngredients list={list} showAll />
                                <ButtonInline
                                  text="Zapisz zmiany"
                                  className="btn-primary btn-large btn-max-width"
                                  onClick={handleSaveList}
                                  isLoading={isFetching.save}
                                  dataTest="save-button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </When>
            <Otherwise>
                <LoaderOnAllSite />
            </Otherwise>
        </Choose>
    );
}

export default ShoppingList;
