import React, { useMemo } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import IncreaseDecreaseInput from '../input/IncreaseDecreaseInput';

function SelectedIngredientsList({
    ingredients, removeIngredient, setIngredientValue, className,
}) {
    const ingredientsList = useMemo(() => ingredients.map((item) => (
        <div key={item.id} className="create-recipe-selected-item-list typo-small">
            <div className="selected-ingredient-metadata-container">
                <div className="selected-ingredient-name">{item.name}</div>
            </div>
            <div className="selected-ingredient-metadata-container multi-items-container">
                <div>
                    <IncreaseDecreaseInput
                      value={item.amount.toString()}
                      onChange={(value) => setIngredientValue(value, item.id)}
                    />
                </div>
                <div>
                    {item.prefix}
                </div>
                <div
                  className="icon-container delete circle"
                  onClick={() => removeIngredient(item.name)}
                  aria-hidden="true"
                >
                    <AiOutlineDelete
                      size={20}
                      className="btn-icon icon-small outline delete"
                    />
                </div>
            </div>
        </div>
    )), [ingredients, removeIngredient, setIngredientValue]);

    return (
        <If condition={ingredients.length}>
            <div className={`selected-ingredients-list-container${className ? ` ${className}` : ''}`}>
                <h3 className="selected-ingredients-title">Lista składników:</h3>
                {ingredientsList}
            </div>
        </If>
    );
}

export default SelectedIngredientsList;
