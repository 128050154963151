import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { clearRecipes, getFilteredRecipes } from '../../redux/actions/recipesAction';
import LoaderOnAllSite from '../Loader/LoaderOnAllSite';
import Pagination from '../Pagination/Pagination';
import RecipesGrid from '../Recipes/RecipesGrid';

function PersonsList() {
    const { id, page } = useParams();
    const dispatch = useDispatch();

    const recipes = useSelector((state) => state.recipes);

    useEffect(() => {
        dispatch(clearRecipes());
        dispatch(getFilteredRecipes({ personId: id, page }));
    }, [dispatch, id, page]);

    return (
        <Choose>
            <When condition={recipes}>
                <RecipesGrid recipes={recipes} />
                <Pagination id={id} pagePath="person" />
            </When>
            <Otherwise>
                <LoaderOnAllSite />
            </Otherwise>
        </Choose>
    );
}

export default PersonsList;
