import React from 'react';
import './Auth.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authAction';
import ButtonInline from '../Buttons/ButtonInline';

function AuthButton({ className }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem('profile'));

    const handleClick = () => {
        if (!user) {
            navigate('/signin');
        } else {
            dispatch(logout(navigate));
        }
    };

    return (
        <ButtonInline
          className={className}
          text={!user ? 'Zaloguj się' : 'Wyloguj'}
          onClick={handleClick}
          dataTest={!user ? 'login-button' : 'logout-button'}
        />
    );
}

export default AuthButton;
