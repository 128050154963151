import React from 'react';
import './Checkbox.css';

function Checkbox({
    label, checked, onChange, isRequire, item, className, dataTest,
}) {
    return (
        <label className={`label-checkbox check-box-container ${className}`}>
            <If condition={isRequire}>
                <strong className="require-symbol">
                    *
                </strong>
            </If>
            <div>{label}</div>
            <input
              className="checkbox-default"
              type="checkbox"
              checked={checked}
              onChange={() => onChange(item)}
            />
            <span className="custom-checkbox" data-test={dataTest} />
        </label>
    );
}

export default Checkbox;
