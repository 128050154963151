import React, { useCallback } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import './BackHistoryArrow.css';

function BackHistoryArrow({ navigateTo }) {
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        if (navigateTo) {
            navigate(navigateTo);

            return;
        }

        navigate(-1);
    }, [navigate, navigateTo]);

    return (
        <div
          className="back-btn"
          onClick={handleNavigate}
          aria-hidden="true"
        >
            <MdArrowBackIos size={20} />
            <p className="typo-small">Wróć</p>
        </div>
    );
}

export default BackHistoryArrow;
