import React, { useState } from 'react';

function Toast({ text, error }) {
    const [shouldShowToastClass, setShouldShowToastClass] = useState(true);

    const handleOnClickToast = () => {
        setShouldShowToastClass(false);
    };

    return (

        <div
          className={`toast-container typo-normal ${shouldShowToastClass ? 'show' : 'hide'}${error ? ' error' : ''}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
            <div className="toast-text">
                {text}
            </div>
            <button
              type="button"
              className={`toast-button${error ? ' error' : ''}`}
              aria-label="close toast"
              onClick={handleOnClickToast}
            >
                X
            </button>
        </div>
    );
}

export default Toast;
