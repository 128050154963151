import React, { forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router';
import withClickOutside from './withClickOutside';

const ContextMenu = forwardRef(({ open, setOpen }, ref) => {
    const navigate = useNavigate();
    const { login } = useSelector((state) => state.auth);

    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, [setOpen]);

    const handleNavigateToSettings = useCallback(() => {
        setOpen(false);
        navigate('/settings');
    }, [navigate, setOpen]);

    return (
        <If condition={login}>
            <section ref={ref}>
                <div
                  aria-hidden="true"
                  onClick={handleToggle}
                  className="user-profile typo-normal"
                  data-test="user-profile"
                >
                    {login}
                    <MdOutlineKeyboardArrowLeft className={`icon context-menu-icon ${open ? 'open' : ''}`} />
                </div>
                <div className={`context-menu-dropdown ${open ? 'open' : 'hidden'}`}>
                    <div
                      className="typo-normal context-menu-items"
                      onClick={handleNavigateToSettings}
                      aria-hidden
                    >
                        Ustawienia
                    </div>
                </div>
            </section>
        </If>
    );
});

export default withClickOutside(ContextMenu);
