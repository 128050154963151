import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserShoppingList } from '../../redux/actions/shoppingListAction';
import ShoppingListItem from './ShoppingListItem';
import './UserShoppingList.css';

function UserShoppingLists() {
    const dispatch = useDispatch();
    const { id } = useSelector((state) => state.auth);
    const { shoppingLists } = useSelector((state) => state.shoppingList);

    useEffect(() => {
        if (!shoppingLists.length) {
            dispatch(getUserShoppingList(id));
        }
    }, [dispatch, id, shoppingLists.length]);

    return (
        <div className="shopping-lists-container">
            <ShoppingListItem />
        </div>
    );
}

export default UserShoppingLists;
