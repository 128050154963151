import React, { useMemo } from 'react';
import './IngredientsList.css';
import LoaderOnAllSite from '../Loader/LoaderOnAllSite';

function IngredientsList({ ingredients }) {
    const ingredientsToDisplay = useMemo(() => ingredients.map((ingredient) => (
        <div className="ingredients-item">
            <p className="left-column" key={ingredient.id}>{ingredient.name}</p>
            <p className="right-column" key={ingredient.id}>
                {`${ingredient.amount} ${ingredient.amount > 1 ? ingredient.prefix : ingredient.pluralPrefix}`}
            </p>
        </div>
    )), [ingredients]);

    return (
        <div className="ingredients-container">
            <h2>Składniki</h2>
            <div className="ingredients">
                <Choose>
                    <When condition={ingredients}>
                        <div className="typo-normal">
                            {ingredientsToDisplay}
                        </div>
                    </When>
                    <Otherwise>
                        <LoaderOnAllSite />
                    </Otherwise>
                </Choose>
            </div>
        </div>
    );
}

export default IngredientsList;
