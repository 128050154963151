import React, { useCallback } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import IconButton from '../Buttons/IconButton';

function Modal({ setOpen, title, children }) {
    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, [setOpen]);

    return (
        <div
          className="modal-background"
          onClick={handleToggle}
          aria-hidden
        >
            <div className="container">
                <div className="modal-container">
                    <div
                      className="modal"
                      onClick={(e) => e.stopPropagation()}
                      aria-hidden
                    >
                        <div className="modal-top">
                            <h2 className="modal-title">{title}</h2>
                            <IconButton
                              icon={<AiOutlineClose size={32} />}
                              onClick={() => setOpen(false)}
                              className="outline dark"
                              dataTest="close-modal-icon"
                            />
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
