import * as api from '../../api/fetchApi';
import {
    GET_FILTERED_RECIPES,
    DELETE_RECIPES,
    GET_RECIPE_BY_ID,
    CLEAR_PAGINATION_DATA,
    GET_PAGINATION_DATA,
    GET_RECIPES_BY_IDS,
} from '../../constants/actionTypes';

export const getFilteredRecipes = ({ personId, categoryId, page }) => async (dispatch) => {
    try {
        const { data } = await api.getFilteredApiRecipes({ personId, categoryId, page });

        dispatch({
            type: CLEAR_PAGINATION_DATA,
        });

        dispatch({
            type: GET_FILTERED_RECIPES,
            data,
        });

        dispatch({
            type: GET_PAGINATION_DATA,
            data: {
                currentPage: data.currentPage,
                numberOfPages: data.numberOfPages,
            },
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const clearRecipes = () => ({
    type: DELETE_RECIPES,
});

export const getRecipeById = (id) => async (dispatch) => {
    try {
        const { data } = await api.getApiRecipeById(id);

        dispatch({
            type: GET_RECIPE_BY_ID,
            data,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const getRecipesByIds = (ids) => async (dispatch) => {
    try {
        const { data } = await api.getApiRecipesByIds(ids);

        dispatch({
            type: GET_RECIPES_BY_IDS,
            data: data.recipes,
        });
    } catch (error) {
        console.log(error);
    }
};
