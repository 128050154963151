import * as api from '../../api/fetchApi';
import {
    GET_ALL_AGREEMENTS,
} from '../../constants/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const getAllAgreements = () => async (dispatch) => {
    try {
        const { data } = await api.getAllAgreements();

        dispatch({
            type: GET_ALL_AGREEMENTS,
            data,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};
