import {
    CHANGE_INGREDIENT_STATE,
    CREATE_SHOPPING_LIST,
    DELETE_USER_SHOPPING_LITS,
    EDIT_USER_SHOPPING_LITS,
    GET_USER_SHOPPING_LIST,
    CLEAR_SHOPPING_LIST,
} from '../../constants/actionTypes';

const initialState = {
    shoppingLists: [],
};

const shoppingListReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SHOPPING_LIST: {
            const newShoppingList = action.data;

            return { ...state, shoppingLists: [newShoppingList, ...state.shoppingLists] };
        }
        case CLEAR_SHOPPING_LIST: {
            return { shoppingLists: [] };
        }
        case GET_USER_SHOPPING_LIST: {
            const { shoppingLists } = action.data;

            return { ...state, shoppingLists };
        }
        case CHANGE_INGREDIENT_STATE: {
            const { listId, ingredientId } = action.data;
            const newState = state.shoppingLists.map((item) => {
                if (item.id === listId) {
                    const { ingredients } = item;
                    const newIngredients = ingredients.map((ingredient) => {
                        if (ingredient.id === ingredientId) {
                            return { ...ingredient, isSelected: !ingredient.isSelected };
                        }

                        return ingredient;
                    });

                    return { ...item, ingredients: [...newIngredients], wasChanged: true };
                }

                return item;
            });

            return { ...state, shoppingLists: newState };
        }
        case EDIT_USER_SHOPPING_LITS: {
            const { id } = action.data;
            const editElements = action.data;

            const newState = state.shoppingLists.map((item) => {
                if (item.id === id) {
                    return { ...item, ...editElements, wasChanged: true };
                }

                return item;
            });

            return { ...state, shoppingLists: newState };
        }
        case DELETE_USER_SHOPPING_LITS: {
            const { id } = action.data;

            const newState = state.shoppingLists.filter((item) => item.id !== id);

            return { ...state, shoppingLists: newState };
        }
        default:
            return state;
    }
};

export default shoppingListReducer;
