import {
    AUTH, LOGOUT, SET_AUTH_DATA,
} from '../../constants/actionTypes';

const initialState = {};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH:
            return action?.data.data;
        case LOGOUT:
            localStorage.clear();

            return initialState;
        case SET_AUTH_DATA: {
            return action?.data.data;
        }
        default:
            return state;
    }
};

export default authReducer;
