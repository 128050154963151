/* eslint-disable no-return-await */
import axios from 'axios';

const API = axios.create({ baseURL: process.env.API_URL });

export const signIn = async (formData) => await API.post('/user/signin', formData);
export const signUp = async (formData, captchaToken) => await API.post('/user/signup', { ...formData, captchaToken });
export const changePassword = async (formData) => await API.patch('/user/changePassword', formData);
export const resetPassword = async (formData) => await API.put('/user/resetPassword', formData);
export const forgotPassword = async (formData) => await API.post('/user/forgotPassword', formData);
export const apiGetUserData = async (id) => await API.get('/user/getUserData', {
    params: {
        id,
    },
});

export const getAllApiCategories = async () => await API.get('/category/getAllCategories');

export const getFilteredApiRecipes = async ({ categoryId, personId, page } = {}) => (
    await API.get('/recipe/getFilteredRecipes', {
        params: {
            categoryId,
            personId,
            page,
        },
    })
);
export const getApiRecipeById = async (id) => await API.get(`/recipe/getRecipeById?id=${id}`);
export const getApiRecipesByIds = async (ids) => await API.post('/recipe/getRecipesById', { ids });

export const getSearchIngredients = async ({ searchQuery, limit }) => (
    await API.get('/ingredient/getSearchIngredients', {
        params: {
            searchQuery,
            limit,
        },
    })
);
export const createShoppingList = async (formData) => await API.post('shoppingList/createShoppingList', formData);
export const getUserShoppingList = async (userId) => await API.get(`/shoppingList/getUserShoppingList?userId=${userId}`);
export const updateShoppingList = async (formData) => await API.patch('/shoppingList/updateUserShoppingList', formData);
export const deleteShoppingList = async (id) => await API.delete(`/shoppingList/deleteById?id=${id}`);

export const addOrDeleteUserFavorite = async (userId, recipeId) => (
    await API.get(`/user/addOrDeleteUserFavorite?userId=${userId}&recipeId=${recipeId}`)
);
export const getUserFavoriteList = async (userId) => await API.get(`/user/getUserFavoriteList?userId=${userId}`);

export const getAllPeople = async () => await API.get('/person/getAllPeople');

export const getAllAgreements = async () => await API.get('/agreement/getAll');

export const getAllProducts = async () => await API.get('/product/all');

export const getBanners = async () => await API.get('/banner/get', {
    params: {
        platformCodename: 'www',
    },
});

export const registerTransaction = async (userId, productId) => await API.post('/transaction/register', { userId, productId });
export const getTransactionById = async (id) => await API.get('/transaction/getById', {
    params: {
        transactionId: id,
    },
});
