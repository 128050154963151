import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createShoppingList } from '../../redux/actions/shoppingListAction';
import ButtonInline from '../Buttons/ButtonInline';
import InputWithLabel from '../input/InputWithLabel';
import SelectedIngredientsList from './SelectedIngredientsList';
import * as api from '../../api/fetchApi';
import './ShoppingList.css';

function CreateList({ buttonText, onSave }) {
    const dispatch = useDispatch();

    const initialState = {
        title: '',
        ingredients: '',
    };

    const { id: userId } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState(initialState);
    const [isFetching, setIsFetching] = useState(false);
    const [searchIngredientsOptions, setSearchIngredientsOptions] = useState('');
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [reqTimeout, setRequestTimeout] = useState(null);

    const handleCreateList = useCallback(() => {
        setIsFetching(true);
        dispatch(createShoppingList({
            userId,
            title: formData.title,
            ingredients: selectedIngredients.map((item) => ({
                id: item.id,
                amount: item.amount,
            })),
        })).then((res) => {
            if (res.status === 201) {
                setFormData((prev) => ({ ...prev, title: '' }));
                setSelectedIngredients([]);
                onSave();
            }
        }).finally(() => {
            setIsFetching(false);
        });
    }, [dispatch, formData.title, onSave, selectedIngredients, userId]);

    const handleChangeInput = useCallback((e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        const shouldSearchIngredients = (e.target.name === 'ingredients');
        if (shouldSearchIngredients) {
            if (reqTimeout) {
                clearTimeout(reqTimeout);
            }

            setRequestTimeout(
                setTimeout(async () => {
                    const { data } = await api.getSearchIngredients({
                        searchQuery: e.target.value,
                        limit: 5,
                    });
                    setSearchIngredientsOptions(data.ingredients);
                }, 500),
            );
        }
    }, [formData, reqTimeout]);

    const handleSelectIngredient = useCallback((ingredient) => {
        setSelectedIngredients((prev) => {
            const isOnList = prev.find((item) => item.name === ingredient.name);

            if (isOnList) {
                return prev;
            }

            return [...prev, { ...ingredient, amount: 1 }];
        });
        setFormData((prev) => ({ ...prev, ingredients: '' }));
        setSearchIngredientsOptions([]);
    }, []);

    const handleAddIngredientValue = useCallback((value, id) => {
        setSelectedIngredients((prev) => {
            const prevState = [...prev];
            const newState = prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, amount: value };
                }

                return item;
            });

            return newState;
        });
    }, []);

    const handleRemoveIngredient = useCallback((name) => {
        setSelectedIngredients((prev) => prev.filter((item) => item.name !== name));
    }, []);

    return (
        <div className="create-list-content">
            <InputWithLabel
              className="typo-small"
              id="listTitle"
              name="title"
              type="text"
              placeholder="Wprowadź nazwe listy"
              value={formData.title}
              onChange={handleChangeInput}
              dataTest="list-title-input"
            />
            <div className="add-ingredients-container">
                <div className="ingredients-list-container">
                    <InputWithLabel
                      className="typo-small"
                      id="Ingredients"
                      name="ingredients"
                      type="text"
                      placeholder="Wyszukaj składnik"
                      value={formData.ingredients}
                      onChange={handleChangeInput}
                      dataTest="search-ingredients-input"
                    >
                        <If condition={searchIngredientsOptions.length}>
                            {searchIngredientsOptions.map((item) => (
                                <div
                                  key={item.id}
                                  onClick={() => handleSelectIngredient(item)}
                                  aria-hidden
                                >
                                    {item.name}
                                </div>
                            ))}
                        </If>
                    </InputWithLabel>
                </div>
                <div className="selected-ingredients-list">
                    <SelectedIngredientsList
                      ingredients={selectedIngredients}
                      setIngredientValue={handleAddIngredientValue}
                      removeIngredient={handleRemoveIngredient}
                    />
                </div>
            </div>
            <ButtonInline
              className="btn-primary btn-large btn-max-width"
              text={buttonText}
              onClick={handleCreateList}
              isLoading={isFetching}
              disabled={!selectedIngredients.length}
              dataTest="create-list-button"
            />
        </div>
    );
}

export default CreateList;
