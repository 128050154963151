import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import categoryReducer from './reducers/categoryReducer';
import shoppingListReducer from './reducers/shoppingListReducer';
import recipesReducer from './reducers/recipesReducer';
import toastReducer from './reducers/toastReducer';
import paginationReducer from './reducers/paginationReducer';
import favouriteReducer from './reducers/favouriteReducer';
import peopleReducer from './reducers/peopleReducer';
import agreementsReducer from './reducers/agreementsReducer';
import productReducer from './reducers/ProductsReducer';
import bannerReducer from './reducers/bannersReducer';
import userDataReducer from './reducers/userDataReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    categories: categoryReducer,
    recipes: recipesReducer,
    toasts: toastReducer,
    shoppingList: shoppingListReducer,
    pagination: paginationReducer,
    favourites: favouriteReducer,
    people: peopleReducer,
    agreements: agreementsReducer,
    products: productReducer,
    banners: bannerReducer,
    userData: userDataReducer,
});

export default rootReducer;
