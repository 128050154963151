import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { signIn } from '../../redux/actions/authAction';
import ButtonInline from '../Buttons/ButtonInline';
import FormCard from '../FormCard/FormCard';
import InputWithLabel from '../input/InputWithLabel';

function SignInForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = { login: '', password: '' };

    const [formData, setFormData] = useState(initialState);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const handleChangeInput = useCallback((e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }, [formData]);

    const handleLogin = useCallback((e) => {
        e.preventDefault();

        setIsFetching(true);
        setError(null);

        dispatch(signIn(formData, navigate))
            .then((res) => {
                if (res.status === 404) {
                    setError(res.data);
                }
                setIsFetching(false);
            });
    }, [dispatch, formData, navigate]);

    return (
        <FormCard title="Zaloguj się" onSubmit={handleLogin} dataTest="login-form">
            <InputWithLabel
              className="label-auth"
              id="label-login"
              name="login"
              type="text"
              placeholder="Login"
              value={formData.login}
              onChange={handleChangeInput}
              error={error}
              dataTest="login-input"
            />
            <InputWithLabel
              className="label-auth"
              id="label-password"
              name="password"
              type="password"
              placeholder="Hasło"
              value={formData.password}
              onChange={handleChangeInput}
              error={error}
              dataTest="password-input"
            />
            <ButtonInline
              type="submit"
              className="btn-primary btn-large btn-max-width"
              text="Zaloguj się"
              isLoading={isFetching}
              disabled={!formData.login || !formData.password}
              dataTest="submit-login-form-button"
            />
            <div className="auth-form-bottom-section typo-normal" data-test="links-container">
                <p className="registration-paragraph">
                    Nie masz konta?
                    {' '}
                    <NavLink
                      className="registration-link"
                      to="/signup"
                      data-test="register-link"
                    >
                        Zarejestruje się!
                    </NavLink>
                </p>
                <NavLink
                  className="registration-link"
                  to="/forgotPassword"
                  data-test="forgot-password-link"
                >
                    Nie pamiętasz hasła?
                </NavLink>
            </div>
        </FormCard>
    );
}

export default SignInForm;
