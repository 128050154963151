import React, { useMemo } from 'react';
import { BiUser, BiTimeFive } from 'react-icons/bi';
import { BsCalculatorFill } from 'react-icons/bs';
import { AiFillSignal } from 'react-icons/ai';
import './RecipeMetadata.css';

function RecipeMetadata({
    servings, duration, level, kcal,
}) {
    const durationToDisplay = useMemo(() => {
        const minutes = duration % 60;
        const hours = (duration - minutes) / 60;

        return `${hours}:${minutes}`;
    }, [duration]);

    return (
        <div className="recipe-small-metadata-container typo-small">
            <div className="metadata-item">
                <BiUser size={24} />
                <p>Liczba porcji:</p>
                <h2>{servings}</h2>
            </div>
            <div className="metadata-item">
                <BiTimeFive size={24} />
                <p>Czas:</p>
                <h2>{durationToDisplay}</h2>
            </div>
            <div className="metadata-item">
                <AiFillSignal size={24} />
                <p>Poziom trudności:</p>
                <h2>{level}</h2>
            </div>
            <div className="metadata-item">
                <BsCalculatorFill size={24} />
                <p>Kcal:</p>
                <h2>{kcal}</h2>
            </div>
        </div>
    );
}

export default RecipeMetadata;
