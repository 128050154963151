import * as api from '../../api/fetchApi';
import { GET_ALL_CATEGORIES } from '../../constants/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const getAllCategories = () => async (dispatch) => {
    try {
        const { data } = await api.getAllApiCategories();

        dispatch({
            type: GET_ALL_CATEGORIES,
            data,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};
