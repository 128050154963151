import {
    ADD_TOAST, ADD_TOAST_ERROR, REMOVE_ERROR_TOAST, REMOVE_TOAST,
} from '../../constants/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const addToastMessage = (message) => async (dispatch) => {
    dispatch({
        type: ADD_TOAST,
        payload: {
            message,
        },
    });

    setTimeout(() => {
        dispatch({
            type: REMOVE_TOAST,
            payload: {
                message,
            },
        });
    }, 5000);
};

export const addToastErrorMessage = (message) => async (dispatch) => {
    dispatch({
        type: ADD_TOAST_ERROR,
        payload: {
            message,
        },
    });

    setTimeout(() => {
        dispatch({
            type: REMOVE_ERROR_TOAST,
            payload: {
                message,
            },
        });
    }, 5000);
};
