import React from 'react';
import './FormCard.css';

function FormCard({
    children, title, onSubmit, dataTest,
}) {
    return (
        <div className="container">
            <div className="form-card-container">
                <form className="form-card" onSubmit={onSubmit} data-test={dataTest}>
                    <h2 className="form-title" data-test="form-title">{title}</h2>
                    {children}
                </form>
            </div>
        </div>
    );
}

export default FormCard;
