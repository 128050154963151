import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserData } from '../redux/actions/userDataAction';

function GetUserData() {
    const dispatch = useDispatch();
    const { id: userId } = useSelector((state) => state.auth);

    if (userId) {
        dispatch(getUserData(userId));
    }

    useEffect(() => {
    }, [userId]);
}

export default GetUserData;
