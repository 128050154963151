import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import ProgressBar from '../progressBar/progressBar';

function ShoppingListItem() {
    const navigate = useNavigate();
    const { shoppingLists } = useSelector((state) => state.shoppingList);
    const { attributes, hasUserActiveProduct } = useSelector((state) => state.userData);

    const handleNavigateToShoppingList = useCallback((item) => {
        navigate(`/shoppingList/${item.id}`);
    }, [navigate]);

    const listItem = useMemo(() => (shoppingLists?.map((item) => {
        const allIngredientsLength = item.ingredients.length;
        const selectedIngredientsLength = item.ingredients.filter((ingredient) => ingredient.isSelected).length;

        return (
            <div
              className="grid-item shopping-list-items-container typo-normal"
              key={item.id}
              onClick={() => handleNavigateToShoppingList(item)}
              aria-hidden="true"
            >
                <h2 className="shopping-list-title">{item.title}</h2>
                <ProgressBar total={allIngredientsLength} completedValue={selectedIngredientsLength} />
            </div>
        );
    })), [handleNavigateToShoppingList, shoppingLists]);

    const availableLists = useMemo(() => {
        if (!attributes?.shoppingListLimit) {
            return 0;
        }

        const maxUserLists = attributes.shoppingListLimit;
        const userExistingLists = shoppingLists.length;

        return maxUserLists - userExistingLists;
    }, [attributes?.shoppingListLimit, shoppingLists.length]);

    return (
        <>
            <div
              className="grid-item shopping-list-items-container typo-normal"
            >
                <If condition={!hasUserActiveProduct}>
                    <a data-tooltip-id="my-tooltip" data-tooltip-content="Rozszerz swój pakiet aby stworzyć więcej list">
                        <AiOutlineInfoCircle className="shopping-list-info-icon" />
                    </a>
                    <Tooltip id="my-tooltip" />
                </If>
                <h2 className="shopping-list-title">Dostępne listy</h2>
                <div className="available-lists-counter">
                    {availableLists}
                </div>
            </div>
            { listItem }
        </>
    );
}

export default ShoppingListItem;
