import React from 'react';
import PersonDetails from '../components/Persons/PersonDetails';
import PersonsList from '../components/Persons/PersonRecipesGird';

function PersonPage() {
    return (
        <div className="container">
            <div className="content">
                <PersonDetails />
                <PersonsList />
            </div>
        </div>
    );
}

export default PersonPage;
