import React, { useState } from 'react';
import './InputWithLabel.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

function InputWithLabel({
    className, id, name, type, placeholder, value, onChange, error, children, label, dataTest,
}) {
    const shouldShowError = error?.input === name ? error.message : null;
    const [passwordType, setPasswordType] = useState('password');

    const handleChangePasswordType = () => {
        setPasswordType((prev) => {
            if (prev === 'password') {
                return 'text';
            }

            return 'password';
        });
    };

    return (
        <div className="input-container">
            <label
              className={`label-default typo-small ${className}`}
              htmlFor={id}
            >
                {label}
            </label>
            <div className="input">
                <input
                  className={`input-box typo-normal ${shouldShowError ? 'input-error' : ''} ${className}`}
                  name={name}
                  id={id}
                  placeholder={placeholder}
                  type={type === 'password' ? passwordType : type}
                  value={value}
                  onChange={onChange}
                  data-test={dataTest}
                />
                <Choose>
                    <When condition={type === 'password' && passwordType === 'password'}>
                        <AiOutlineEye
                          className="icon-eye"
                          onClick={handleChangePasswordType}
                          data-test="eye-icon-show-password"
                        />
                    </When>
                    <When condition={type === 'password' && passwordType !== 'password'}>
                        <AiOutlineEyeInvisible
                          className="icon-eye"
                          onClick={handleChangePasswordType}
                          data-test="eye-icon-hidden-password"
                        />
                    </When>
                </Choose>
            </div>
            <If condition={shouldShowError}>
                <p className="error-message typo-small" data-test="input-error">{error.message}</p>
            </If>
            <If condition={children}>
                <div className="search-dropdown" data-test="input=dropdown">
                    <div className="search-dropdown-position-absolute typo-normal">
                        {children}
                    </div>
                </div>
            </If>
        </div>
    );
}

export default InputWithLabel;
