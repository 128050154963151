import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import BackHistoryArrow from '../components/BackHistoryArrow/BackHistoryArrow';
import RecipesGrid from '../components/Recipes/RecipesGrid';
import { getUserShoppingList } from '../redux/actions/shoppingListAction';
import { getRecipesByIds } from '../redux/actions/recipesAction';
import LoaderOnAllSite from '../components/Loader/LoaderOnAllSite';

function RecipesGridPage() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { id: userId } = useSelector((state) => state.auth);
    const { shoppingLists } = useSelector((store) => store.shoppingList);

    const [isFetching, setIsFetching] = useState(true);

    const recipes = useSelector((state) => state.recipes);

    useEffect(() => {
        if (userId && !shoppingLists.length) {
            dispatch(getUserShoppingList(userId));
        }
    }, [dispatch, shoppingLists.length, userId]);

    useEffect(() => {
        if (shoppingLists.length) {
            const recipesIds = shoppingLists.find((item) => item.id === id).relatedRecipe;

            dispatch(getRecipesByIds(recipesIds))
                .then(() => setIsFetching(false));
        }
    }, [dispatch, id, shoppingLists]);

    return (
        <div className="container home-page-container">
            <BackHistoryArrow />
            <h2 className="page-title">Powiązane przepisy z listą zakupową</h2>

            <Choose>
                <When condition={!isFetching && recipes.length}>
                    <RecipesGrid recipes={recipes} />
                </When>
                <Otherwise>
                    <LoaderOnAllSite />
                </Otherwise>

            </Choose>
        </div>
    );
}

export default RecipesGridPage;
