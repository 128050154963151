import { ADD_OR_DELETE_FAVORITE, GET_USER_FAVORITE_LIST, CLEAR_FAVOURITES } from '../../constants/actionTypes';

const initialState = [];

const favouriteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_FAVORITE_LIST: {
            const { favourites } = action.data;

            return favourites;
        }
        case ADD_OR_DELETE_FAVORITE: {
            const { favourites, id } = action.data;
            const oldState = [...state];

            if (favourites.id) {
                return [favourites, ...oldState];
            }

            return oldState.filter((item) => item.id !== id);
        }
        case CLEAR_FAVOURITES: {
            return initialState;
        }
        default:
            return state;
    }
};

export default favouriteReducer;
