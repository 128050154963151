import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import RecipesGrid from '../components/Recipes/RecipesGrid';
import { clearRecipes, getFilteredRecipes } from '../redux/actions/recipesAction';
import { getAllCategories } from '../redux/actions/categoryAction';
import BackHistoryArrow from '../components/BackHistoryArrow/BackHistoryArrow';
import Pagination from '../components/Pagination/Pagination';
import LoaderOnAllSite from '../components/Loader/LoaderOnAllSite';

function CategoryPage() {
    const dispatch = useDispatch();
    const { id, page } = useParams();

    const allCategories = useSelector((state) => state.categories);
    const categoryData = useMemo(() => (
        allCategories.find((item) => item.id === id)
    ), [allCategories, id]);
    const recipes = useSelector((state) => state.recipes);

    useEffect(() => {
        dispatch(clearRecipes());
        dispatch(getFilteredRecipes({ categoryId: id, page }));

        if (!allCategories.length) {
            dispatch(getAllCategories());
        }
    }, [allCategories.length, dispatch, id, page]);

    return (
        <div className="container">
            <div className="content">
                <BackHistoryArrow navigateTo="/" />
                <h2 className="page-title">{categoryData?.title}</h2>
                <Choose>
                    <When condition={recipes.length}>
                        <RecipesGrid recipes={recipes} />
                        <Pagination id={id} pagePath="category" />
                    </When>
                    <Otherwise>
                        <LoaderOnAllSite />
                    </Otherwise>
                </Choose>
            </div>
        </div>
    );
}

export default CategoryPage;
