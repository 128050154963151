import React from 'react';
import './Buttons.css';

function IconButton({
    className, icon, onClick, disabled, dataTest,
}) {
    return (
        <button
          type="button"
          className={`btn-icon${className ? ` ${className}` : ''}`}
          onClick={onClick}
          disabled={disabled}
          data-test={dataTest}
        >
            {icon}
        </button>
    );
}

export default IconButton;
