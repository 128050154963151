import React from 'react';

function BannerItem({ title, image, description }) {
    return (
        <div
          className="banner-container"
          style={{
            backgroundImage: `url(${image || '/assets/banner-background.jpg'})`,
          }}
        >
            <div className="banner-text-section typo-normal">
                <h2 className="banner-title">{title}</h2>
                <div className="banner-description">{description}</div>
            </div>
        </div>
    );
}

export default BannerItem;
