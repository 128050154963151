import React from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';
import HomePage from './HomePage';
import PageNotFound from './PageNotFound';
import SignInForm from '../components/Auth/SignInForm';
import SignUpForm from '../components/Auth/SingUpForm';
import CategoryPage from './CategoryPage';
import RecipePage from './RecipePage';
import UserListPage from './UserListPage';
import ShoppingList from '../components/UserShoppingList/ShoppingList';
import EditShoppingList from '../components/UserShoppingList/EditShoppingList';
import PersonPage from './PersonPage';
import FavouritesPage from './FavouritesPage';
import SettingsPage from './SettingsPage';
import VerifyEmailPage from './VerifyEmailPage';
import ResetPasswordPage from './ResetPasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import RecipesGridPage from './RecipesGridPage';
import PriceListPage from './PriceListPage';
import PrivatePolicyPage from './PrivatePolicyPage';
import TransactionStatus from './TransactionStatus';

function Pages() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/signin" element={<SignInForm />} />
            <Route path="signup" element={<SignUpForm />} />
            <Route path="/category/:id/:page" element={<CategoryPage />} />
            <Route path="/recipe/:id" element={<RecipePage />} />
            <Route path="/lists" element={<UserListPage />} />
            <Route path="/shoppingList/:id" element={<ShoppingList />} />
            <Route path="/edit/shoppingList/:id" element={<EditShoppingList />} />
            <Route path="/edit/shoppingList/recipes/:id" element={<RecipesGridPage />} />
            <Route path="/person/:id/:page" element={<PersonPage />} />
            <Route path="/favourites" element={<FavouritesPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/verifyEmail" element={<VerifyEmailPage />} />
            <Route path="/resetPassword" element={<ResetPasswordPage />} />
            <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
            <Route path="/priceList" element={<PriceListPage />} />
            <Route path="/privatePolicy" element={<PrivatePolicyPage />} />
            <Route path="transactionId/:id" element={<TransactionStatus />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default Pages;
