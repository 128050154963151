import React, { useState } from 'react';
import './VerifyEmailPage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputWithLabel from '../components/input/InputWithLabel';
import { resetPassword } from '../api/fetchApi';
import { addToastMessage } from '../redux/actions/toastAction';
import ButtonInline from '../components/Buttons/ButtonInline';
import FormCard from '../components/FormCard/FormCard';

function ResetPasswordPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState({ input: 'password', message: '' });
    const [isFetching, setIsFetching] = useState(false);

    const handleResetPassword = async () => {
        setError((prev) => ({ ...prev, message: '' }));
        setIsFetching(true);

        const password = newPassword.trim();

        if (newPassword.includes(' ')) {
            setError((prev) => ({ ...prev, message: 'Sapcje są niedopuszczalne' }));
            setIsFetching(false);

            return;
        }

        if (password.length < 8) {
            setError((prev) => ({ ...prev, message: 'Hasło jest za krótkie' }));
            setIsFetching(false);

            return;
        }

        try {
            await resetPassword({
                resetLink: token,
                newPassword: password,
            });
            navigate('/');
            dispatch(addToastMessage('Twoje hasło zostało zresetowane, możesz sie zalogować.'));
        } catch (err) {
            setError((prev) => ({ ...prev, message: 'Link resetujący hasło jest nieaktywny. Wygeneruj nowy link do resetu hasła.' }));
        } finally {
            setIsFetching(false);
        }
    };

    return (
        <FormCard title="Zresetuj hasło">
            <InputWithLabel
              className="label-auth"
              id="label-login"
              name="password"
              type="password"
              placeholder="Hasło"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value); }}
              error={error}
              dataTest="reset-password-input"
            />
            <ButtonInline
              className="btn-primary btn-large btn-max-width"
              text="Zresetuj hasło"
              onClick={handleResetPassword}
              isLoading={isFetching}
              disabled={!newPassword.length}
              dataTest="reset-password-button"
            />
        </FormCard>
    );
}

export default ResetPasswordPage;
