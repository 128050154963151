import { GET_ALL_PEOPLE } from '../../constants/actionTypes';

const initialState = [];

const peopleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PEOPLE: {
            const { people } = action.data;

            return people;
        }
        default:
            return state;
    }
};

export default peopleReducer;
