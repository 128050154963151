import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineWarning } from 'react-icons/ai';
import { changePassword } from '../api/fetchApi';
import InputWithLabel from '../components/input/InputWithLabel';
import { addToastMessage } from '../redux/actions/toastAction';
import './SettingsPage.css';
import ButtonInline from '../components/Buttons/ButtonInline';

function SettingsPage() {
    const dispatch = useDispatch();
    const { id, login, email } = useSelector((state) => state.auth);

    const [changePasswordForm, setChangePasswordForm] = useState({});
    const [wrongPasswordData, setWrongPasswordData] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setIsChangingPassword(true);
        setWrongPasswordData(false);
        try {
            await changePassword(changePasswordForm);
            dispatch(addToastMessage('Towje hasło zostało zmienione'));
            setChangePasswordForm((prev) => ({ ...prev, oldPassword: '', newPassword: '' }));
        } catch (error) {
            dispatch(addToastMessage('Nie udało się zmienić hasła, sprawdź poprawność danych'));
            setWrongPasswordData(true);
        }
        setIsChangingPassword(false);
    };

    useEffect(() => {
        const initialChangePasswordForm = {
            oldPassword: '',
            newPassword: '',
        };

        setChangePasswordForm({ userId: id, ...initialChangePasswordForm });
    }, [id]);

    return (
        <div className="container home-page-container">
            <div className="settings-container">
                <div className="settings-section-container">
                    <h3 className="settings-section-title typo-normal">
                        Użytkownik
                    </h3>
                    <div className="typo-small">
                        {login}
                    </div>
                </div>
                <div className="settings-section-container">
                    <h3 className="settings-section-title typo-normal">
                        Adres email
                    </h3>
                    <div className="typo-small">
                        {email}
                    </div>
                </div>
                <div className="settings-section-container">
                    <h3 className="settings-section-title typo-normal">
                        Zmień hasło
                    </h3>
                    <InputWithLabel
                      className=""
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Stare hasło"
                      value={changePasswordForm.oldPassword}
                      onChange={(e) => { setChangePasswordForm((prev) => ({ ...prev, oldPassword: e.target.value })); }}
                      type="password"
                      dataTest="old-password-input"
                    />
                    <InputWithLabel
                      className=""
                      id="newPassword"
                      name="newPassword"
                      placeholder="Nowe hasło"
                      value={changePasswordForm.newPassword}
                      onChange={(e) => { setChangePasswordForm((prev) => ({ ...prev, newPassword: e.target.value })); }}
                      type="password"
                      dataTest="new-password-input"
                    />
                    <If condition={wrongPasswordData}>
                        <div className="wrong-password-message typo-small">
                            <AiOutlineWarning size={24} />
                            <div>
                                Błedne dane, sprawdź poprawność wpisanych danych i spróbuj ponownie
                            </div>
                        </div>
                    </If>
                    <ButtonInline
                      className="btn-primary"
                      text="Zmień hasło"
                      onClick={(e) => { handleChangePassword(e); }}
                      disable={
                        changePasswordForm.oldPassword?.length < 8
                        || changePasswordForm.newPassword?.length < 8
                      }
                      isLoading={isChangingPassword}
                      dataTest="save-button"
                    />
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;
