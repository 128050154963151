import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import ListSection from '../PageSections/ListSection';

function CategoriesList() {
    const navigate = useNavigate();
    const categories = useSelector((state) => state.categories);

    const categoryItem = useMemo(() => (
        categories.map((item) => (
            <div
              className="item-container"
              onClick={() => { navigate(`/category/${item.id}/1`); }}
              aria-hidden="true"
              key={item.id}
            >
                <div
                  className="vertical-list-item-image"
                  style={{ backgroundImage: `url(${item.image})` }}
                />
                <p className="list-item-title typo-normal">
                    {item.title}
                </p>
            </div>
        ))
    ), [categories, navigate]);

    return (
        <ListSection
          title="Kategorie"
          style={{ margin: 'auto' }}
        >
            <Slider
              infinite={categoryItem.length > 3}
              speed={500}
              slidesToShow={4}
              slidesToScroll={4}
              responsive={[
                {
                  breakpoint: 1336,
                  settings: {
                    infinite: categoryItem.length > 2,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 1030,
                  settings: {
                    infinite: categoryItem.length > 1,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 690,
                  settings: {
                    infinite: categoryItem.length > 0,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
                {categoryItem}
            </Slider>
        </ListSection>
    );
}

export default CategoriesList;
