/* eslint-disable consistent-return */
import * as api from '../../api/fetchApi';
import {
    AUTH, LOGOUT, SET_AUTH_DATA, CLEAR_SHOPPING_LIST, CLEAR_FAVOURITES,
} from '../../constants/actionTypes';
import { addToastMessage } from './toastAction';

export const signIn = (formData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);
        localStorage.setItem('profile', JSON.stringify({ ...data }));

        dispatch({
            type: AUTH,
            data,
        });

        dispatch(addToastMessage('Zostałeś zalogowany!'));

        navigate('/');
    } catch (error) {
        // eslint-disable-next-line no-console
        if (error.response.status === 403) {
            navigate('/verifyEmail');
        }

        return error.response;
    }
};

export const signUp = (formData, navigate, captchaToken) => async (dispatch) => {
    try {
        await api.signUp(formData, captchaToken);

        dispatch(addToastMessage('Zostałeś zarejestrowany!'));
        navigate('/verifyEmail');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);

        return error.response;
    }
};

export const logout = (navigate) => async (dispatch) => {
    try {
        dispatch({
            type: LOGOUT,
        });

        dispatch({
            type: CLEAR_SHOPPING_LIST,
        });

        dispatch({
            type: CLEAR_FAVOURITES,
        });

        dispatch(addToastMessage('Zostałeś wylogowany'));

        navigate('/');
    } catch (error) {
        console.log(error);
    }
};

export const setAuthData = () => async (dispatch) => {
    try {
        const data = JSON.parse(localStorage.getItem('profile'));

        dispatch({
            type: SET_AUTH_DATA,
            data,
        });
    } catch (error) {
        console.log(error);
    }
};
