import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HomePageBanner from '../components/Banners/HomePageBanner';
import CategoriesList from '../components/Categories/CategoriesList';
import PersonsList from '../components/Persons/PersonList';
import { getBanners } from '../redux/actions/bannersAction';
import { getAllCategories } from '../redux/actions/categoryAction';
import { getAllPeople } from '../redux/actions/peopleAction';
import './HomePage.css';

function HomePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCategories());
        dispatch(getAllPeople());
        dispatch(getBanners());
    }, [dispatch]);

    return (
        <div className="container home-page-container">
            <HomePageBanner />
            <CategoriesList />
            <PersonsList />
        </div>
    );
}

export default HomePage;
